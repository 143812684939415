export const COLORS = {
  //Brand old design
  mephaltiDarkBlue: '#02001E',
  mephaltiBlue: '#003166',

  //Brand
  mephaltiLightCyan: '#DEFFFF',
  mephaltiTeal: '#008080',
  //General
  white: '#FFFFFF',
  black: '#36454f',
  muted: ' #52525B',
  border: '#E4E4E4',
  shadow: '#306889',

  //color shades
  lightBlue: {
    100: '#F0F3FF',
    200: '#F5F7FF',
  },
  gray: {
    50: '#FAFAFA',
    100: '#d6d8db',
    200: '##929292',
    300: '#676767',
    400: '#36454F',
    500: '#676767',
  },

  //info colors
  warning: '#e7d038',
  danger: '#e73838',
};
