import about1 from "../assets/about1.png";
import about2 from "../assets/about2.png";
import about3 from "../assets/about3.png";
import about4 from "../assets/about4.png";

import missionImg from "../assets/mission.png";
import visionImg from "../assets/vision.png";

import missionIcon from "../assets/icons/launch.png";
import visionIcon from "../assets/icons/statistics.png";

import innovationIcon from "../assets/icons/idea.png";
import exxcellenceIcon from "../assets/icons/success.png";
import collaborationIcon from "../assets/icons/brainstorm.png";
import integrityIcon from "../assets/icons/leadership.png";
import accountabilityIcon from "../assets/icons/skill.png";
import customerIcon from "../assets/icons/help.png";

export const ABOUT_DETAILS = {
  // description: `We are a team of passionate and dedicated
  // individuals who provide top-notch software development services
  // to businesses across industries. Our goal is to help our
  // clients achieve their digital transformation goals by
  // building custom software solutions that are tailored to
  // their specific needs.`,
  // description: `We Offer comprehensive solutions across the petroleum and tech industries. For petroleum retailers and bulk buyers, we simplify transactions with mobile access for purchasing, tracking, and monitoring, addressing issues like product shortages and siphoning. Our innovative Gas Monitoring System eliminates the inconvenience of running out of cooking gas by automatically detecting low levels. In the blockchain/software development sector, especially in Edo State, it provides high-tech software development services and develops innovative blockchain products, aiming to resolve the complexities businesses face in this rapidly evolving area with customized, efficiency-driving solutions.`,
  description: ` We believe in the power of technology to improve lives, whether through smarter IoT integration, innovative software, or sustainable energy solutions. If you're curious about how our solutions can transform your business for a smarter future, explore what we do`,

  //about hero images
  images: [
    {
      alt: "an image",
      src: about1,
    },
    {
      alt: "an image",
      src: about2,
    },
    {
      alt: "an image",
      src: about3,
    },
    {
      alt: "an image",
      src: about4,
    },
  ],
};

//mission statement
export const MISSION_STATEMENT = {
  icon: missionIcon,

  text: `We are dedicated to providing businesses with 
cutting-edge technology solutions by utilizing industry 
best practices to deliver outstanding results that help 
them achieve their goals and foster growth.`,

  image: {
    alt: "mission image",
    src: missionImg,
  },
};

//vision statement
export const VISION_STATEMENT = {
  icon: visionIcon,

  //   text: `Our vision is to become a leading provider of
  // technology solutions that drive business growth. We aspire
  // to be recognized for our commitment to excellence and our
  // ability to deliver exceptional value to our clients.`,
  text: `“To build innovative technology solutions for a smarter future”`,

  image: {
    alt: "mission image",
    src: visionImg,
  },
};

export const CORE_VALUES = [
  {
    icon: innovationIcon,
    heading: "Innovation",
    details: `We believe in exploring new ideas to deliver 
    solutions that help our clients stay ahead of the curve.`,
  },
  {
    icon: exxcellenceIcon,
    heading: "Excellence",
    details: `We are committed to delivering exceptional 
    quality in every aspect of our work.`,
  },
  {
    icon: collaborationIcon,
    heading: "Collaboration",
    details: `We foster teamwork, working closely with our 
    clients and partners to ensure project success`,
  },
  {
    icon: integrityIcon,
    heading: "Integrity",
    details: `We operate with transparency, ensuring that 
    our actions align with our clients' best interests.`,
  },
  {
    icon: accountabilityIcon,
    heading: "Accountability",
    details: `We take full responsibility for our actions 
    and outcomes while delivering on our commitments.`,
  },
  {
    icon: customerIcon,
    heading: "Customer focus",
    details: `We put our clients first by providing 
    personalized solutions that exceed their expectations.`,
  },
];

export const FOUNDERS = [
  {
    name: "Jennifer Agah",
    details: "Lorem ipsum dolor sit amet",
  },
  {
    name: "Jessica Sunday",
    details: "Lorem ipsum dolor sit amet",
  },
  {
    name: "Hakeem Gustra",
    details: "Lorem ipsum dolor sit amet",
  },
];
