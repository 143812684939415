import styled from "styled-components";

export const StyledProjectCard = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  padding-block: 64px;

  &:nth-of-type(even) {
    div {
      grid-column: 1/2;
      grid-row: 1/1;
    }
    img {
      grid-column: 2/3;
      grid-row: 1/1;
    }
  }

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    gap: 50px;

    &:nth-of-type(even) {
      div {
        grid-column: unset;
        grid-row: unset;
      }
      img {
        grid-column: unset;
        grid-row: unset;
      }
    }
  }
`;

export const ProjectImage = styled.img`
  width: 100%;
  height: 450px;
  object-fit: contain;

  @media (max-width: 579px) {
    height: 350px;
  }
`;

export const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;

  h3 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  p {
    line-height: 32px;
    color: ${(props) => props.theme.colors.gray[300]};
  }

  a {
    display: flex;
    gap: 10px;
  }
`;

export const ProjectType = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue[200]};
  width: fit-content;
  padding: 20px 35px;
  border-radius: 16px;
  font-weight: 500;
`;
