import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: ${props => props.theme.colors.gray[50]};
  padding-block: ${props => props.theme.spacing[8]};
  color: ${props => props.theme.colors.muted};
`;

export const FooterLinks = styled.section`
  display: grid;
  grid-template-columns: 2fr repeat(3, 1fr);
  align-items: flex-start;
  gap: 50px;
  padding-bottom: ${props => props.theme.spacing[8]};

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 579px) {
    grid-template-columns: 1fr;
  }
`;

export const LinkGroup = styled.div`
  max-width: 334px;
  display: grid;
  justify-items: flex-start;
  gap: ${props => props.theme.spacing[4]};

  p,
  a {
    font-weight: 500;
    /* font-style: italic; */
  }
`;

export const LinksHeading = styled.h3`
  font-size: ${props => props.theme.sizes.xl};
  font-weight: 500;
  color: #000000;
`;

export const FooterLink = styled.a`
  &:hover {
    color: ${props => props.theme.colors.brand.accent};
  }
`;

export const CopyrightInfo = styled.section`
  border-top: 1px solid ${props => props.theme.colors.gray[100]};
  padding-top: ${props => props.theme.spacing[4]};
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing[4]};
  justify-content: space-between;

  @media (max-width: 579px) {
    flex-direction: column;
  }
`;

export const SocialLinks = styled(motion.div)`
  display: flex;
  align-items: center;
  gap: ${props => props.theme.spacing[3]};
`;

export const SocialLink = styled(motion.a).attrs({
  target: '_blank',
  rel: 'noopener noreferrer',
})`
  width: 40px;
  height: 40px;
  display: grid;
  place-content: center;
  background-color: ${props => props.theme.colors.brand.accent};
  color: ${props => props.theme.colors.white};
  border-radius: 100%;
`;

export const CopyrightText = styled.p`
  color: ${props => props.theme.colors.gray};
  font-weight: 500;
`;
