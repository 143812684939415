import { StyledCheckBox } from './style';

interface Props
  extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
}
const CheckCard: React.FC<Props> = ({
  id,
  label,
  ...rest
}) => {
  return (
    <StyledCheckBox>
      <input id={id} type="checkbox" {...rest} />
      <label htmlFor={id}>{label}</label>
    </StyledCheckBox>
  );
};

export default CheckCard;
