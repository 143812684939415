import { motion } from 'framer-motion';
import styled from 'styled-components';
import { Container } from '../Container';

export const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.brand.dark};
  width: 100%;
  height: ${props => props.theme.height.header};

  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const HeaderContainer = styled(Container)`
  width: ${props => props.theme.width.container};
  height: 100%;
  margin-inline: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  position: relative;

  a {
    width: max-content;
  }
`;

export const HeaderMenu = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: flex-end;
  gap: 2rem;

  @media (max-width: 900px) {
    position: absolute;
    grid-template-columns: 1fr;
    justify-items: center;
    padding: ${props => props.theme.spacing[6]};
    border-bottom-left-radius: ${props => props.theme.radius.md};
    border-bottom-right-radius: ${props => props.theme.radius.md};
    gap: 2rem;
    background-color: ${props => props.theme.colors.brand.dark};
    width: 100%;
    top: -500px;
    opacity: 0;
    transition: ${props => props.theme.transition.fast};

    &[data-active='true'] {
      top: ${props => props.theme.height.header};
      opacity: 1;
    }
  }

  button,
  a {
    min-width: max-content;
  }
`;

export const Navigation = styled(motion.nav)`
  display: flex;
  justify-self: center;
  gap: 55px;

  @media (max-width: 840px) {
    gap: 30px;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

// old desing

// export const NavLink = styled(motion.a).attrs({
//   whileHover: { scale: 1.1 },
//   whileTap: { scale: 0.95 },
// })`
//   color: ${props => props.theme.colors.white};
//   width: max-content;

//   &[data-active] {
//     font-weight: 500;
//     color: ${props => props.theme.colors.white};
//   }
// `;

export const NavLink = styled(motion.a).attrs({
  whileHover: { scale: 1.1 },
  whileTap: { scale: 0.95 },
})`
  color: ${props => props.theme.colors.gray[500]};
  width: max-content;
  font-weight: 500;

  &[data-active] {
    font-weight: 500;
    color: ${props => props.theme.colors.gray[500]};
  }
`;

export const MenuButton = styled.button`
  gap: 6px;
  padding: 6px;
  display: none;

  @media (max-width: 900px) {
    display: grid;
  }
`;

export const MenuBar = styled.div`
  height: 2px;
  width: 24px;
  background-color: ${props => props.theme.colors.black};
  border-radius: 100px;
  transition: transform ${props => props.theme.transition.normal};

  ${MenuButton}[data-active='true'] &:first-child {
    transform: translateY(4px) rotate(45deg);
  }
  ${MenuButton}[data-active='true'] &:last-child {
    transform: translateY(-4px) rotate(-45deg);
  }
`;
