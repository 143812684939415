import { forwardRef } from 'react';
import {
  InputMessage,
  InputWrapper,
  StyledInput,
  StyledLabel,
  StyledSearchInput,
} from './style';
import { SearchIcon } from '../Icons';

interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  error?: boolean;
  message?: string;
  bg?: 'gray' | 'transparent';
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      error = false,
      message,
      id,
      type,
      bg = 'default',
      ...props
    },
    ref
  ) => {
    return (
      <InputWrapper>
        {label ? (
          <StyledLabel htmlFor={id}>{label}</StyledLabel>
        ) : (
          false
        )}

        {type === 'search' ? (
          <StyledSearchInput
            data-error={error}
            data-bg={bg}
          >
            <SearchIcon />
            <input
              id={id}
              ref={ref}
              type={type}
              {...props}
            />
          </StyledSearchInput>
        ) : (
          <StyledInput
            id={id}
            ref={ref}
            type={type}
            {...props}
            data-bg={bg}
            data-error={error}
          />
        )}

        {message ? (
          <InputMessage>{message}</InputMessage>
        ) : (
          false
        )}
      </InputWrapper>
    );
  }
);

Input.displayName = 'Input';
export default Input;
