import styled from "styled-components";
import { Container } from "../Container";

export const StyledSection = styled.section`
  padding-bottom: 100px;
`;

export const NewsletterContainer = styled(Container)`
  background-color: ${(props) => props.theme.colors.brand.blue};
  margin-inline: auto;
  padding-block: 100px;
  padding-inline: 50px;
  border-radius: ${(props) => props.theme.radius.xxl};
  display: grid;
  place-items: center;
  gap: 40px;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    position: absolute;
    width: 236px;
    height: 236px;
    border-radius: 100%;
    border: 7px solid;
  }

  &::before {
    top: -86px;
    left: -98px;
    border-color: ${(props) => props.theme.colors.gray[100]};
    opacity: 0.3;
  }

  &::after {
    bottom: -140px;
    right: -110px;
    border-color: ${(props) => props.theme.colors.white};
    opacity: 0.8;
  }
`;

export const TextGroup = styled.div`
  text-align: center;
  display: grid;
  gap: 12px;
`;

export const Heading = styled.h2`
  color: ${(props) => props.theme.colors.white};
  font-size: ${(props) => props.theme.sizes.heading5};
  font-weight: 500;
  line-height: 42px;
`;

export const Text = styled.p`
  color: ${(props) => props.theme.colors.white};
  line-height: 24px;
  font-weight: 300;
`;

export const InputGroup = styled.form`
  width: 100%;
  max-width: 549px;
  display: grid;
  align-items: start;
  grid-template-columns: 2.5fr 1fr;
  gap: ${(props) => props.theme.spacing[3]};

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
