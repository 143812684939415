import { PortfolioCard as PortfolioCardInterface } from "src/interfaces/constants";
import {
  ButtonText,
  ButtonWrapper,
  PorfolioCardWrapper,
  // CardHeading,
  PortfolioCardImage,
  // CardText,
  StyledPortolioCard,
} from "./style";
// import { RightArrow } from "../Icons";
import { MotionProps } from "framer-motion";
import {
  containerVariants,
  itemVariants,
  // itemVariants
} from "src/utils/framer-motion";
import Button from "../Button";
import { RightArrow } from "../Icons";
// import { useNavigate } from "react-router-dom";
// import Button from "../Button";
import { motion } from "framer-motion";

interface portfolioProps extends MotionProps {
  portfolio: PortfolioCardInterface;
}

const PortfolioCard: React.FC<portfolioProps> = ({
  portfolio: { title, image, link, icon },
  ...rest
}) => {
  // const navigate = useNavigate();

  return (
    <StyledPortolioCard
      {...rest}
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
    >
      <PorfolioCardWrapper>
        {icon}
        <ButtonWrapper>
          <Button
            asLink
            variant="outlined"
            // onClick={handleNavigate}
            variants={itemVariants}
            href={link}
          >
            <ButtonText>Learn more </ButtonText>
            <motion.span
              initial={{ x: "-3px" }}
              animate={{ x: "3px" }}
              transition={{
                repeat: Infinity,
                repeatType: "reverse",
                duration: 0.6,
                restSpeed: 3,
              }}
            >
              <RightArrow />
            </motion.span>
          </Button>
        </ButtonWrapper>
      </PorfolioCardWrapper>
      <PortfolioCardImage src={image} alt={title} />
    </StyledPortolioCard>
  );
};

export default PortfolioCard;
