import { FooterLinks } from "src/interfaces/constants";
import { CONTACT_LINKS } from "./contact";

export const FOOTER_TEXT = `Transforming buisness and indidual experiences through innovation`;

export const FOOTER_LINKS: FooterLinks = [
  {
    title: "Company",
    links: [
      {
        text: "About Us",
        url: "/about",
      },
      {
        text: "Careers",
        url: "/careers",
      },
      {
        text: "Services",
        url: "/services",
      },
    ],
  },
  {
    title: "Help",
    links: [
      {
        text: "Customer Support",
        url: "/contact",
      },
    ],
  },
  {
    title: "Contact Us",
    links: CONTACT_LINKS,
  },
];
