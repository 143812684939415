import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
//custom font
@font-face {
  font-family: Larsseit;
  src: url('/fonts/Larsseit-Thin.otf') format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: Larsseit;

  src: url('/fonts/Larsseit-Light.otf') format('opentype');
  font-weight: 400;
}

@font-face {
  font-family: Larsseit;
  src: url('/fonts/Larsseit-Medium.otf') format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: Larsseit;
  src: url('/fonts/Larsseit-Bold.otf') format('opentype');
  font-weight: 700;
}

@font-face {
  font-family: Larsseit;
  src: url('/fonts/Larsseit-ExtraBold.otf') format('opentype');

  font-weight: 800;
}


//reset styles by tailwind preflight
*,
::before,
::after {
  box-sizing: border-box; 
  border-width: 0; 
  border-style: solid;
  
}

:focus-visible {
    outline: none;
    box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.9);
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4; 
  tab-size: 4;
}

body {
  margin: 0;
  line-height: inherit;
  font-family: Larsseit, Inter, sans-serif;
  font-weight: 400;
  margin-top: ${props => props.theme.height.header};
  color: ${props => props.theme.colors.black};

}

hr {
  height: 0;
  color: inherit;
  border-top-width: 1px;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; 
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit; 
  color: inherit; 
  margin: 0; 
  padding: 0; 
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
  appearance: button;
  background-color: transparent;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  appearance: textfieled;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol,
ul,
menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #9c9c9c;
}

::-ms-input-placeholder {
  color: #9c9c9c;

}

button,
[role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block; 
}

img,
video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}


//Swiper customization

:root {
  --swiper-navigation-top-offset: 40%;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-button-next, .swiper-button-prev {
  /* display: none; */
  background-color: white;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  box-shadow: 0 2px 10px #a9babd74;

  &::after {
    font-size: 16px;
    color: ${props => props.theme.colors.brand.accent};
    font-weight: 800;
    }

  &[aria-disabled='true'] {
    opacity: 1;

    &::after {
    color: ${props => props.theme.colors.gray[100]};
    }
  }
}


`;

export default GlobalStyle;
