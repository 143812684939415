import { Container } from 'src/components/Container';
import styled from 'styled-components';

export const Hero = styled.section`
  padding-block: 100px;
  background-color: ${props =>
    props.theme.colors.brand.dark};

  @media (max-width: 767px) {
    padding-block: 50px;
  }
`;

export const HeroTextGroup = styled(Container)`
  color: ${props => props.theme.colors.gray};
  display: grid;
  justify-items: flex-start;

  p {
    margin-bottom: 40px;
    font-size: 18px;
    font-weight:600;
    display: flex;
    gap: 15px;
    align-items: center;
    max-width: 529px;
    color: #36454F;
  }

  @media (max-width: 767px) {
    text-align: center;
    justify-items: center;
  }
`;

export const JobDetails = styled.article`
  padding-block: 64px;
  width: ${props => props.theme.width.container};
  max-width: 760px;
  margin-inline: auto;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin: 3rem 0 1.38rem;

    :first-child {
      margin-top: 0;
    }
  }

  ul {
    list-style: disc;
    list-style-position: inside;
  }

  p,
  li {
    font-size: 18px;
    line-height: 32px;
    color: ${props => props.theme.colors.gray[300]};
    margin-bottom: 1rem;
  }
`;

export const JobTitle = styled.p`
  font-size: 18px;
  line-height: 32px;
  color: ${props =>
    props.theme.colors.brand.dark} !important;
`;

export const ApplySection = styled.section`
  padding-block: 50px;
`;

export const ApplyContainer = styled.div`
  width: ${props => props.theme.width.container};
  max-width: 760px;
  margin-inline: auto;

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 50px;
  }
`;
