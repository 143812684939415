import { useLocation } from 'react-router-dom';
import Footer from '../Footer';
import Header from '../Header';
import Newsletter from '../Newsletter';
import ServiceRequest from '../ServiceRequest';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();
  const isServiceRequestPage =
    location.pathname === '/services/request';

  return (
    <>
      <Header />
      <main>
        {children}
        {isServiceRequestPage ? false : <ServiceRequest />}
        <Newsletter />
      </main>
      <Footer />
    </>
  );
};

export default Layout;
