import styled from 'styled-components';
import { motion } from 'framer-motion';

export const StyledHeadingGroup = styled(motion.div)`
  max-width: min(
    410px,
    ${props => props.theme.width.container}
  );
  margin-inline: auto;
  margin-bottom: 80px;
  text-align: center;

  @media (max-width: 579px) {
    margin-bottom: 40px;
  }
`;

export const Heading = styled(motion.h2)`
  font-weight: 700;
  font-size: ${props => props.theme.sizes.heading4};
  line-height: 48px;
  margin-bottom: 16px;

  @media (max-width: 579px) {
    font-size: ${props => props.theme.sizes.heading5};
  }
`;

export const SubHeading = styled(motion.p)`
  font-size: ${props => props.theme.sizes.lg};
  color: ${props => props.theme.colors.gray[300]};

  @media (max-width: 579px) {
    font-size: ${props => props.theme.sizes.base};
  }
`;
