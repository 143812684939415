import styled from 'styled-components';

export const JobForm = styled.form`
  display: grid;
  gap: 40px;
`;

export const FormGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 48px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
