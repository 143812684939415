import { CardIcon, StyledServiceCard } from './style';
import { RightArrow } from '../Icons';
import { ServiceCard } from 'src/interfaces/constants';
import Button from '../Button';
import { useNavigate } from 'react-router';

interface Props {
  details: ServiceCard;
}

const ServicePageCard: React.FC<Props> = ({
  details: { title, details, icon },
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(
      `/services/request?${encodeURIComponent(title)}`
    );
    window.scrollTo(0, 0);
  };

  return (
    <StyledServiceCard>
      <CardIcon>{icon}</CardIcon>
      <h3>{title}</h3>
      <p>{details}</p>
      <Button variant="ghost" onClick={handleNavigate}>
        Request service <RightArrow />
      </Button>
    </StyledServiceCard>
  );
};

export default ServicePageCard;
