import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: grid;
  width: 100%;
  gap: 12px;
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  padding: 12px 16px;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  background: transparent;
  outline: none;
  color: ${props => props.theme.colors.white};
  transition: border-color
    ${props => props.theme.transition.fast};

  &[data-error='true'] {
    border-color: ${props => props.theme.colors.danger};
  }

  &[data-bg='gray'] {
    background-color: ${props =>
      props.theme.colors.gray[50]};
    color: ${props => props.theme.colors.black};
  }

  &[data-bg='transparent'] {
    color: ${props => props.theme.colors.black};
  }
`;

export const StyledLabel = styled.label`
  color: ${props => props.theme.colors.black};
  font-weight: 500;
`;

export const InputMessage = styled.p`
  font-size: ${props => props.theme.sizes.sm};
  color: ${props => props.theme.colors.danger};
`;

export const StyledSearchInput = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  padding-left: 10px;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.border};
  border-radius: 4px;
  background: transparent;

  svg {
    color: #9c9c9c;
  }

  &[data-error='true'] {
    border-color: ${props => props.theme.colors.danger};
  }

  &[data-bg='gray'] {
    background-color: ${props =>
      props.theme.colors.gray[50]};
  }

  input {
    padding: 12px 16px;
    width: 100%;
    outline: none;
    background: transparent;
  }
`;
