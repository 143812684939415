import { COLORS } from "../colors";

export const theme = {
  colors: {
    white: COLORS.white,
    black: COLORS.black,
    muted: COLORS.muted,
    border: COLORS.border,
    shadow: COLORS.shadow,
    brand: {
      // old design
      // dark: COLORS.mephaltiDarkBlue,
      // accent: COLORS.mephaltiBlue,
      dark: COLORS.mephaltiLightCyan,
      accent: COLORS.mephaltiTeal,
      blue: COLORS.mephaltiBlue,
    },
    lightBlue: COLORS.lightBlue,
    gray: COLORS.gray,
    warning: COLORS.warning,
    danger: COLORS.danger,
  },

  gradients: {
    shadow1: "linear-gradient(#306889, #248177, #1A8657)",
    shadow2: "linear-gradient(#1A8657, #248177, #306889)",
  },

  sizes: {
    base: "1rem", //16px
    sm: "0.875rem", //14px
    lg: "1.125rem", //18px
    xl: "1.25rem", //20px
    xlg: "1.5rem", // 24px
    // heading1: '4rem', //64px // old design
    heading1: "5.25rem", // 84px
    heading2: "3.5rem", //56px
    heading3: "3rem", //48px
    heading4: "2.5rem", //40px
    heading5: "2rem", //32px
    heading6: "1.5rem", //24px
    heading80: "5rem", //80px
  },

  lineHight: {
    base: "24px",
    sm: "18px",
    lg: "28px",
    xl: "32px",
  },

  spacing: {
    1: "8px",
    2: "12px",
    3: "16px",
    4: "24px",
    5: "32px",
    6: "48px",
    7: "64px",
    8: "72px",
  },

  radius: {
    sm: "6px",
    md: "9px",
    lg: "12px",
    xl: "16px",
    xxl: "32px",
    full: "999px",
  },

  transition: {
    slow: "500ms",
    normal: "250ms",
    fast: "120ms",
  },

  width: {
    container: "86%",
    max: "1440px",
  },

  height: {
    header: "88px",
  },
};
