import { containerVariants, itemVariants } from 'src/utils/framer-motion';
import Button from '../Button';
import {
  Heading,
  ServiceRequestContainer,
  ServiceRequestSection,
  Accent,
  Text,
} from './style';

const ServiceRequest = () => {
  return (
    <ServiceRequestSection>
      <ServiceRequestContainer
        variants={containerVariants}
        initial="hidden"
        whileInView="visible"
      >
        <Heading variants={itemVariants}>
          Ready to <Accent>Start</Accent> a project?
        </Heading>

        <Text variants={itemVariants}>
          Feel free to reach out to us if you have a project you would like us
          to work on
        </Text>

        <Button asLink href="/services/request" variant="primary">
          Request for a service
        </Button>
      </ServiceRequestContainer>
    </ServiceRequestSection>
  );
};

export default ServiceRequest;
