import { z } from 'zod';

export type ServiceRequestFormProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  message: string;
};

export const serviceRequestFormSchema: z.ZodType<ServiceRequestFormProps> =
  z.object({
    firstName: z
      .string()
      .trim()
      .nonempty('Firstname is required'),
    lastName: z
      .string()
      .trim()
      .nonempty('Lastname is required'),
    email: z.string().email(),
    phoneNumber: z
      .string()
      .trim()
      .nonempty('Phone number is required'),
    message: z
      .string()
      .trim()
      .nonempty('Message is required'),
  });
