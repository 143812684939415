import { z } from 'zod';

export type NewsletterFormProps = {
  email: string;
};

export const NewsletterSchema: z.ZodType<NewsletterFormProps> =
  z.object({
    email: z.string().email(),
  });
