import { z } from 'zod';

export type ContactFormProps = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  subject: string;
  message: string;
};

export const contactFormSchema: z.ZodType<ContactFormProps> =
  z.object({
    firstName: z
      .string()
      .trim()
      .nonempty('Firstname is required'),
    lastName: z
      .string()
      .trim()
      .nonempty('Lastname is required'),
    email: z.string().email(),
    phoneNumber: z
      .string()
      .trim()
      .nonempty('Phone number is required'),
    subject: z
      .string()
      .trim()
      .nonempty(
        'Please enter the subject for your message'
      ),
    message: z
      .string()
      .trim()
      .nonempty('Message is required'),
  });
