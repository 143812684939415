import { MotionProps, Variants } from "framer-motion";
// import { Autoplay } from "swiper";

export const containerVariants: Variants = {
  hidden: { opacity: 1 },

  visible: {
    opacity: 1,
    transition: {
      duration: 500,
      delayChildren: 0.2,
      staggerChildren: 0.1,
    },
  },
};

export const itemVariants: Variants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export const getScrollRevealVariants = (
  direction: "left" | "right"
): Variants => {
  return {
    hidden: {
      y: direction === "left" ? -30 : 30,
      scale: 0.95,
      opacity: 0,
    },
    visible: {
      y: 0,
      scale: 1,
      opacity: 1,
    },
  };
};

export const getScrollRevealProps = (
  direction: "left" | "right"
): MotionProps => {
  return {
    initial: "hidden",
    whileInView: "visible",
    variants: getScrollRevealVariants(direction),
    transition: {
      type: "spring",
    },
  };
};
