import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { theme } from "./utils/styled-components/theme";
import GlobalStyle from "./utils/styled-components/global-style";

import "swiper/css";
import "swiper/css/a11y";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css/autoplay";

import Landing from "./pages/Landing";
import About from "./pages/About";
import ProjectsPage from "./pages/Projects";
import CareersApplyPage from "./pages/CareersApply";
import ContactPage from "./pages/Contact";
import ServiceRequestPage from "./pages/ServiceRequest";
import ServicesPage from "./pages/Services";
import CareersPage from "./pages/Careers";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Toaster toastOptions={{ duration: 5000 }} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/services/request" element={<ServiceRequestPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/careers/:slug" element={<CareersApplyPage />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects" element={<ProjectsPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/services" element={<ServicesPage />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
