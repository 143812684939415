import { getScrollRevealProps } from 'src/utils/framer-motion';
import { HeroHeading, HeroText, TextGroup } from './style';
import Button from '../Button';

interface Props {
  heading: string;
  text: string;
  cta?: string;
}

const HeroTextGroup: React.FC<Props> = ({
  heading,
  text,
  cta,
}) => {
  return (
    <TextGroup>
      <HeroHeading {...getScrollRevealProps('left')}>
        {heading}
      </HeroHeading>

      <HeroText {...getScrollRevealProps('left')}>
        {text}
      </HeroText>

      {cta ? <Button>{cta}</Button> : false}
    </TextGroup>
  );
};

export default HeroTextGroup;
