import { containerVariants, itemVariants } from 'src/utils/framer-motion';
import Button from '../Button';
import Logo from '../Logo';
import {
  HeaderContainer,
  HeaderMenu,
  MenuBar,
  MenuButton,
  NavLink,
  Navigation,
  StyledHeader,
} from './style';
import { useState } from 'react';
import useClickOutside from 'src/utils/hooks/useClickOutside';
import { useRef } from 'react';
import useScrollDirection from 'src/utils/hooks/useScrollDirection';
import useScroll from 'src/utils/hooks/useScroll';

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const menuBtnRef = useRef<HTMLButtonElement>(null);

  useClickOutside(
    menuRef,
    () => {
      setMenuOpen(false);
    },
    [menuBtnRef]
  );

  const scrollDirection = useScrollDirection();
  const scrollHeight = useScroll();
  const isHeaderFixed = scrollDirection === 'up' && scrollHeight > 60;

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  };

  return (
    <StyledHeader data-fixed={isHeaderFixed}>
      <HeaderContainer
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <Logo variants={itemVariants} dark />

        <HeaderMenu ref={menuRef} data-active={menuOpen}>
          <Navigation>
            <NavLink href="/about" variants={itemVariants}>
              About Us
            </NavLink>
            <NavLink href="/projects" variants={itemVariants}>
              Portfolio
            </NavLink>

            <NavLink href="/services" variants={itemVariants}>
              Services
            </NavLink>

            <NavLink href="/careers" variants={itemVariants}>
              Careers
            </NavLink>
          </Navigation>

          <Button asLink href="/contact" variants={itemVariants}>
            Contact Us
          </Button>
        </HeaderMenu>

        <MenuButton
          ref={menuBtnRef}
          title="Menu"
          style={{ justifySelf: 'right' }}
          data-active={menuOpen}
          onClick={toggleMenu}
        >
          <MenuBar />
          <MenuBar />
        </MenuButton>
      </HeaderContainer>
    </StyledHeader>
  );
};

export default Header;
