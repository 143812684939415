import { Container } from "src/components/Container";
import styled from "styled-components";

export const ServicesHero = styled.section`
  padding-block: 80px;
  background-color: ${(props) => props.theme.colors.brand.dark};
  color: ${(props) => props.theme.colors.gray[400]};

  @media (max-width: 767px) {
    padding-block: 40px;
  }
`;

export const ServicesHeroContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    gap: 20px;
    text-align: center;
  }
`;

export const ServiceHeroImg = styled.img`
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-radius: 16px 80px 16px 16px;

  @media (max-width: 579px) {
    height: 320px;
  }
`;

export const ServicesSection = styled.section`
  padding-block: 100px;

  @media (max-width: 767px) {
    padding-block: 50px;
  }
`;

export const ServiceHeading = styled(Container)`
  display: grid;
  gap: 16px;
  margin-bottom: 64px;

  h2 {
    display: flex;
    span {
      font-size: 24px;
      font-weight: 500;
      line-height: 48px;
      text-align: center;
      margin-inline: auto;
    }
  }
  p {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: center;
  }
`;

export const ServicesGrid = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const FaqSection = styled.section`
  padding-block: 64px;
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 328px;
    height: 328px;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.lightBlue[200]};
    z-index: -1;
  }

  &::before {
    top: -140px;
    right: -100px;
  }

  &::after {
    left: -100px;
    bottom: -140px;
  }
`;

export const Faq = styled.div`
  width: ${(props) => props.theme.width.container};
  max-width: 810px;
  margin-inline: auto;
`;
