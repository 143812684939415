import projectsHeroImg from "../assets/projectsHero.png";
import { Project } from "src/interfaces/constants";
// import productImg from "../assets/products/product.png";
import virpemImg from "../assets/virpem-portfolio.png";
import virgasImg from "../assets/virgas-portfolio.png";
import mephaltiImg from "../assets/mepahlti-portfolio.png";

export const PROJECTS_HERO = {
  heading: "Our Portfolio",
  text: `Melphati technologies portfolio shows a list of products transforming businesses and individual experiences`,
  image: {
    src: projectsHeroImg,
    alt: "our projects",
  },
};

export const PROJECTS: Project[] = [
  {
    name: "Virgas",
    type: "Mephalti Product",
    desc: ` Never run out of gas with our virtual gas monitoring system that detects low cooking gas(LPG) levels and allows you to order refil `,
    image: virgasImg,
    link: "https://virgasapp.com",
  },
  {
    name: "Virpem",
    type: "Mephalti Product",
    desc: `Virtual Petroleum Market(B2B marketplace enabled with IoT) that allows retailers/marketers source a variety of petroleum products from manufacturers, track product realtime and manage inventory all in one place.`,
    image: virpemImg,
    link: "https://virpem.com",
  },
  {
    name: "Mephalti Technology",
    type: "Software Development",
    desc: `we provide high-tech software development services, aiming to resolve the complexities businesses face in this rapidly evolving area with customized, efficiency-driving solutions.`,
    image: mephaltiImg,
    link: "mephalti.com",
  },
];
