import { ServiceCard as ServiceCardInterface } from 'src/interfaces/constants';
import { CardHeading, CardImage, CardText, StyledServiceCard } from './style';
import { RightArrow } from '../Icons';
import { MotionProps } from 'framer-motion';
import { containerVariants, itemVariants } from 'src/utils/framer-motion';
import { useNavigate } from 'react-router-dom';
import Button from '../Button';

interface ServiceCardProps extends MotionProps {
  service: ServiceCardInterface;
}

const ServiceCard: React.FC<ServiceCardProps> = ({
  service: { title, details, image },
  ...rest
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/services/request?${encodeURIComponent(title)}`);
    window.scrollTo(0, 0);
  };

  return (
    <StyledServiceCard
      {...rest}
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
    >
      <CardImage src={image} alt={title} />

      <CardHeading variants={itemVariants}>{title}</CardHeading>

      <CardText variants={itemVariants}>{details}</CardText>

      <Button variant="ghost" onClick={handleNavigate} variants={itemVariants}>
        Request service <RightArrow />
      </Button>
    </StyledServiceCard>
  );
};

export default ServiceCard;
