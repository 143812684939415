import { SocialLinks } from 'src/interfaces/constants';
import {
  Facebook,
  Instagram,
  Twitter,
  LinkedIn,
  Whatsapp,
} from '../components/Icons/social';

export const SOCIAL_LINKS: SocialLinks = [
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/profile.php?id=100095540623098&mibextid=ZbWKwL',
    icon: <Facebook />,
  },
  {
    title: 'Twitter',
    url: 'https://x.com/mephaltihq?t=2bYkPhbKyBBDt0FRNB5Now&s=09',
    icon: <Twitter />,
  },
  {
    title: 'Instagram',
    url: 'https://instagram.com/mephaltihq?igshid=a3B6czN0cTgzNDRu',
    icon: <Instagram />,
  },
  {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/mephalti-technologies-ltd/',
    icon: <LinkedIn />,
  },
  {
    title: 'WhatsApp',
    url: 'https://wa.link/diercf',
    icon: <Whatsapp />,
  },
];
