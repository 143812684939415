import { HTMLMotionProps, motion } from 'framer-motion';

interface LogoProps extends HTMLMotionProps<'a'> {}

const VirpemLogo: React.FC<LogoProps> = () => {
  return (
    <motion.a href="https://virpem.com">
      <svg
        width="120%"
        height="auto"
        viewBox="0 0 361 117"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M108.666 0.861057C120.599 -0.431323 126.051 4.71503 125.022 16.3017C118.398 25.8023 111.129 26.3165 103.214 17.8457C101.132 10.6998 102.95 5.03774 108.666 0.861057Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M158.5 28.6548C169.366 29.785 180.27 30.2992 191.211 30.1988C190.979 31.8232 191.237 33.3673 191.99 34.831C205.886 24.2063 215.232 27.2945 220.028 44.0954C222.112 58.9632 220.554 73.3739 215.355 87.3291C210.752 93.1611 204.781 94.9614 197.441 92.7334C193.694 90.2289 190.839 86.8829 188.874 82.6969C188.295 94.0304 188.555 105.353 189.653 116.666C182.384 116.666 175.115 116.666 167.846 116.666C171.279 90.4281 171.538 64.179 168.625 37.9192C167.855 40.4422 167.597 43.0161 167.846 45.6395C159.743 42.0341 153.771 44.0939 149.933 51.8157C148.674 65.3371 149.453 78.7179 152.269 91.9613C144.481 91.9613 136.693 91.9613 128.904 91.9613C130.015 80.9105 131.052 69.8457 132.02 58.764C131.49 49.1738 130.451 39.6516 128.904 30.1988C135.674 29.9425 142.423 30.2004 149.154 30.9709C150.23 32.6184 151.009 34.4203 151.49 36.3751C153.14 33.0415 155.476 30.4675 158.5 28.6548Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M241.056 28.6547C264.474 25.3905 275.117 35.1691 272.988 57.9918C271.17 63.9102 267.276 67.7704 261.305 69.5723C255.011 69.8394 248.78 70.6115 242.613 71.8884C244.291 77.1012 247.925 79.6736 253.517 79.6087C260.218 79.1131 265.93 76.5407 270.651 71.8884C272.676 78.9139 272.676 85.8622 270.651 92.7332C231.81 98.4911 216.493 82.2784 224.7 44.0953C228.965 37.5485 234.417 32.4006 241.056 28.6547Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M308.039 28.6547C318.026 26.967 323.997 31.0835 325.952 41.0071C332.863 27.2928 342.209 25.233 353.99 34.8309C356.169 39.2515 357.726 43.8837 358.663 48.7275C359.415 63.1474 360.194 77.5582 360.999 91.9612C353.729 91.9612 346.461 91.9612 339.192 91.9612C341.003 79.1378 341.782 66.2711 341.528 53.3596C338.329 42.8384 333.656 41.8101 327.509 50.2715C326.53 64.2669 327.308 78.1634 329.846 91.9612C323.096 91.9612 316.346 91.9612 309.596 91.9612C312.134 78.1619 312.912 64.2654 311.933 50.2715C306.739 41.9969 302.066 42.5126 297.914 51.8156C297.401 65.2582 298.18 78.6406 300.25 91.9612C292.462 91.9612 284.674 91.9612 276.885 91.9612C280.469 71.4838 280.988 50.8969 278.443 30.1987C285.713 30.1987 292.981 30.1987 300.25 30.1987C300.018 31.8231 300.277 33.3671 301.029 34.8309C303.205 32.4067 305.542 30.3469 308.039 28.6547Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M102.426 31.7429C109.695 31.7429 116.963 31.7429 124.233 31.7429C121.937 51.8898 122.456 71.9626 125.791 91.9613C118.002 91.9613 110.214 91.9613 102.426 91.9613C106.259 71.853 106.259 51.7802 102.426 31.7429Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M61.6107 26.3301C71.3763 34.7199 75.0998 45.2437 72.7803 57.9017C67.5563 64.7943 62.6385 64.5878 58.0263 57.2801C59.0744 49.063 60.43 40.8246 62.0936 32.5636C61.7248 30.4605 61.5639 28.3826 61.6107 26.3301Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M25.4176 7.95097C29.1841 7.81388 32.5871 9.03094 35.6291 11.6002C41.4973 16.9324 46.1177 23.1754 49.4889 30.3301C52.3709 43.2428 55.6784 55.9237 59.4109 68.3772C60.9544 71.7943 63.2972 74.5553 66.4373 76.658C72.7669 78.1161 77.6445 81.4687 81.0693 86.7148C80.5738 88.9914 79.648 91.0939 78.2896 93.022C68.5064 94.8273 59.4342 92.9186 51.0717 87.299C45.5888 82.4352 40.9052 76.9155 37.0189 70.7373C35.1085 57.8988 33.6664 45.0205 32.695 32.1002C31.1511 23.7283 28.7257 15.6782 25.4176 7.95097Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M80.1971 18.7392C93.0197 29.2319 97.5402 42.4925 93.7599 58.5205C88.9672 70.2758 77.1219 106.01 69.891 88.0892C61.292 83.7373 70.3669 71.8019 74.6047 67.5892C82.1703 54.8622 83.9323 41.3587 79.89 27.0772C79.9927 24.2972 80.0944 21.5192 80.1971 18.7392Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M247.288 41.0074C250.749 40.5102 253.606 41.5401 255.855 44.0955C260.021 57.7188 255.088 63.3793 241.058 61.0802C240.037 53.312 242.115 46.6201 247.288 41.0074Z"
          fill="#5C5473"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M194.331 44.0962C197.275 43.7827 199.612 44.8111 201.341 47.1843C203.64 54.2499 204.16 61.4561 202.898 68.8012C200.587 74.9589 196.174 77.5313 189.658 76.5215C189.4 67.2432 189.66 57.9788 190.437 48.7284C192.065 47.3804 193.362 45.8363 194.331 44.0962Z"
          fill="#5B5373"
        />
      </svg>
    </motion.a>
  );
};

export default VirpemLogo;
