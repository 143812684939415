import styled from 'styled-components';
import { JobForm } from '../JobApplyForm/style';

export const StyledContactForm = styled(JobForm)`
  padding: 2rem;
  border: 1px solid ${props => props.theme.colors.gray[100]};
  border-radius: 12px;

  button {
    justify-self: flex-start;
  }
`;
