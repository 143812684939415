import styled from 'styled-components';

export const StyledServiceCard = styled.article`
  padding: 2rem;
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 2px 62px rgba(0, 0, 0, 0.04);
  display: grid;
  justify-items: start;
  gap: 15px;
  border-radius: 16px;

  h3 {
    font-size: 22px;
    font-weight: 700;
  }

  p {
    font-size: 18px;
    color: ${props => props.theme.colors.gray[300]};
  }

  a {
    display: flex;
    gap: 10px;
    font-weight: 500;
  }
`;

export const CardIcon = styled.div`
  margin-bottom: 1rem;
  background-color: ${props => props.theme.colors.gray[50]};
  width: 70px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  svg {
    width: 30px;
  }
`;
