import { FOOTER_LINKS, FOOTER_TEXT } from "src/constants/footer";
import Logo from "../Logo";
import {
  CopyrightInfo,
  CopyrightText,
  FooterLink,
  FooterLinks,
  LinkGroup,
  LinksHeading,
  SocialLink,
  SocialLinks,
  StyledFooter,
} from "./style";
import Button from "../Button";
import { SOCIAL_LINKS } from "src/constants/social";
import { containerVariants, itemVariants } from "src/utils/framer-motion";
import { Container } from "../Container";

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <FooterLinks>
          <LinkGroup>
            <Logo dark />
            <p>{FOOTER_TEXT}</p>
            <Button asLink href="/contact" variant="primary">
              Contact Us
            </Button>
          </LinkGroup>

          {FOOTER_LINKS.map((footerLink) => (
            <LinkGroup key={footerLink.title}>
              <LinksHeading>{footerLink.title}</LinksHeading>

              {footerLink.links.map((link, i) => (
                <FooterLink href={link.url} key={i}>
                  {link.text}
                </FooterLink>
              ))}
            </LinkGroup>
          ))}
        </FooterLinks>

        <CopyrightInfo>
          <SocialLinks
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
          >
            {SOCIAL_LINKS.map((link, i) => (
              <SocialLink
                key={i}
                href={link.url}
                title={link.title}
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.95 }}
                variants={itemVariants}
              >
                {link.icon}
              </SocialLink>
            ))}
          </SocialLinks>

          <CopyrightText>
            &copy; 2023 Mephalti. All rights reserved
          </CopyrightText>
        </CopyrightInfo>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
