import Button from '../Button';
import FileInput from '../FileInput';
import Input from '../Input';
import Textarea from '../TextArea';
import { FormGroup, JobForm } from './style';

const JobApplyForm = () => {
  return (
    <JobForm>
      <FormGroup>
        <Input
          type="text"
          label="First Name"
          placeholder="Enter First Name"
          id="firstname"
          bg="transparent"
        />
        <Input
          type="text"
          label="Last Name"
          placeholder="Enter Last Name"
          id="lastname"
          bg="transparent"
        />
      </FormGroup>

      <FormGroup>
        <Input
          type="email"
          label="Email Address"
          placeholder="Enter Email Address"
          id="email"
          bg="transparent"
        />
        <Input
          type="text"
          label="Phone Number"
          placeholder="Enter Mobile Number"
          id="email"
          bg="transparent"
        />
      </FormGroup>

      <FileInput
        id="cv"
        label="Drop files here or upload from device"
        handleFile={file => console.log(file)}
      />

      <Textarea
        label="Tell us why you want to work with us"
        bg="transparent"
      />

      <Button>Submit Application</Button>
    </JobForm>
  );
};

export default JobApplyForm;
