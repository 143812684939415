import {
  containerVariants,
  itemVariants,
} from 'src/utils/framer-motion';
import {
  Heading,
  StyledHeadingGroup,
  SubHeading,
} from './style';

interface Props {
  heading: string;
  subheading: string;
}

const HeadingGroup: React.FC<Props> = ({
  heading,
  subheading,
}) => {
  return (
    <StyledHeadingGroup
      variants={containerVariants}
      initial="hidden"
      whileInView="visible"
    >
      <Heading variants={itemVariants}>{heading}</Heading>

      <SubHeading variants={itemVariants}>
        {subheading}
      </SubHeading>
    </StyledHeadingGroup>
  );
};

export default HeadingGroup;
