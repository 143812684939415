import { zodResolver } from '@hookform/resolvers/zod';
import Button from '../Button';
import Input from '../Input';
import { FormGroup } from '../JobApplyForm/style';
import Textarea from '../TextArea';
import {
  ContactFormProps,
  contactFormSchema,
} from './schema';
import { StyledContactForm } from './style';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import contactService from 'src/network/services/contact-service';
import { toast } from 'react-hot-toast';

const ContactForm = () => {
  const [isLoading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm<ContactFormProps>({
    resolver: zodResolver(contactFormSchema),
  });

  const handleContact = async (
    formData: ContactFormProps
  ) => {
    setLoading(true);

    try {
      const { status } = await contactService(formData);

      if (status === 201) {
        toast.success('Email Sent');

        reset();
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error('Something went wrong');
      console.log(JSON.stringify(error, null, 2));
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContactForm
      onSubmit={handleSubmit(handleContact)}
      onChange={() => clearErrors()}
    >
      <FormGroup>
        <Input
          bg="transparent"
          label="First Name"
          placeholder="Enter First Name"
          id="firstname"
          {...register('firstName')}
          error={!!errors.firstName}
          message={errors.firstName?.message}
        />
        <Input
          bg="transparent"
          label="Last Name"
          placeholder="Enter Last Name"
          id="lastname"
          {...register('lastName')}
          error={!!errors.lastName}
          message={errors.lastName?.message}
        />
      </FormGroup>

      <FormGroup>
        <Input
          bg="transparent"
          type="email"
          label="Email Address"
          placeholder="Enter Email Address"
          id="email"
          {...register('email')}
          error={!!errors.email}
          message={errors.email?.message}
        />
        <Input
          bg="transparent"
          label="Phone Number"
          placeholder="Enter Mobile Number"
          type="number"
          id="phone"
          {...register('phoneNumber')}
          error={!!errors.phoneNumber}
          message={errors.phoneNumber?.message}
        />
      </FormGroup>

      <Input
        bg="transparent"
        label="Subject"
        placeholder="Enter the subject of you email"
        id="message"
        {...register('subject')}
        error={!!errors.subject}
        message={errors.subject?.message}
      />

      <Textarea
        bg="transparent"
        label="Your message"
        id="message"
        placeholder="Give us specific details"
        {...register('message')}
        error={!!errors.message}
        message={errors.message?.message}
      />

      <Button type="submit" loading={isLoading}>
        Submit
      </Button>
    </StyledContactForm>
  );
};

export default ContactForm;
