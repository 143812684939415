import { HtmlHTMLAttributes, useState } from 'react';
import { CloseIcon, UploadIcon } from '../Icons';
import {
  CustomFileInput,
  FileImagePreview,
  ImageIcon,
  Label,
  Preview,
  Text,
  UploadFileWrapper,
} from './style';

interface Props
  extends HtmlHTMLAttributes<HTMLInputElement> {
  id: string;
  label: string;
  handleFile?: (file: File | null) => void;
}

const allowedFileTypes = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

const maxFileSize = 50 * 1024 * 1024; // 50MB in bytes

const FileInput: React.FC<Props> = ({
  id,
  label,
  handleFile,
  ...props
}) => {
  const [file, setLocalFile] = useState<File | null>(null);

  const [previewUrl, setPreviewUrl] = useState<
    string | null
  >(null);

  const handleRemoveImage = (e: React.MouseEvent) => {
    e.preventDefault();

    setLocalFile(null);
    setPreviewUrl(null);

    previewUrl && URL.revokeObjectURL(previewUrl);

    if (handleFile) {
      handleFile(null);
    }
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];

    if (selectedFile) {
      if (allowedFileTypes.includes(selectedFile.type)) {
        if (selectedFile.size <= maxFileSize) {
          setLocalFile(selectedFile);
          setPreviewUrl(URL.createObjectURL(selectedFile));

          if (handleFile) {
            handleFile(selectedFile);
          }
        } else {
          alert(
            'Selected file is too large. Maximum file size is 50MB.'
          );
        }
      } else {
        alert(
          'Selected file type is not allowed. Please select a JPG, JPEG, PNG or PDF file.'
        );
      }
    } else {
      setLocalFile(null);
      setPreviewUrl(null);
    }
  };

  return (
    <UploadFileWrapper data-active={file ? true : false}>
      <ImageIcon>
        <UploadIcon />
      </ImageIcon>

      <Label htmlFor={id}>{label}</Label>

      <CustomFileInput
        id={id}
        type="file"
        accept=".jpg,.jpeg,.png,.pdf, .doc, .docx"
        onChange={handleFileChange}
        {...props}
      />

      {/* show the file preview if a file is selcted */}
      {file ? (
        [
          'application/pdf',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ].includes(file.type) ? (
          //documents preview
          <Preview>
            <button
              title="remove image"
              onClick={handleRemoveImage}
            >
              <CloseIcon />
            </button>

            <Text>
              <span>FileName:</span> {file?.name} <br />{' '}
            </Text>
          </Preview>
        ) : previewUrl ? (
          //images preview
          <Preview>
            <button
              title="remove image"
              onClick={handleRemoveImage}
            >
              <CloseIcon />
            </button>

            <FileImagePreview src={previewUrl} alt="" />
          </Preview>
        ) : (
          //ending of tenary operator
          false
        )
      ) : (
        //no file selected
        <Text>
          Supported file formats: PDF, JPG, PNG, DOCS
        </Text>
      )}
    </UploadFileWrapper>
  );
};

export default FileInput;
