import Layout from 'src/components/Layout';
import {
  ApplyContainer,
  ApplySection,
  Hero,
  HeroTextGroup,
  JobDetails,
  JobTitle,
} from './style';
import { HeroHeading } from '../Landing/style';
import Button from 'src/components/Button';
import {
  DownArrow,
  LocationIcon,
} from 'src/components/Icons';
import { JOB_DETAILS } from 'src/constants/jobApply';
import React from 'react';
import JobApplyForm from 'src/components/JobApplyForm';

const CareersApplyPage = () => {
  return (
    <Layout>
      <Hero>
        <HeroTextGroup>
          <HeroHeading>Front-End Developer</HeroHeading>
          <p>
            <LocationIcon /> Lagos, Nigeria
          </p>
          <Button asLink href="#job-apply-form">
            Apply for this position <DownArrow />
          </Button>
        </HeroTextGroup>
      </Hero>

      <JobDetails>
        <h2>Job description</h2>
        <JobTitle>
          <span>{JOB_DETAILS.position}</span> &#124;{' '}
          <span>{JOB_DETAILS.experience}</span>
        </JobTitle>
        <p>{JOB_DETAILS.description}</p>

        {JOB_DETAILS.sections.map((section, i) => (
          <React.Fragment key={i}>
            <h2>{section.heading}</h2>

            {section.type === 'list' ? (
              <ul>
                {section.text.map((jobRequirement, i) => (
                  <li key={i}>{jobRequirement}</li>
                ))}
              </ul>
            ) : (
              <p>{section.text}</p>
            )}
          </React.Fragment>
        ))}
      </JobDetails>

      <ApplySection>
        <ApplyContainer>
          <h2 id="job-apply-form">
            Apply for this position
          </h2>

          <JobApplyForm />
        </ApplyContainer>
      </ApplySection>
    </Layout>
  );
};

export default CareersApplyPage;
