import Layout from "src/components/Layout";
import { Hero, HeroHeading, HeroText } from "../Landing/style";
import {
  AboutHeroContainer,
  AboutHeroImages,
  AboutImage,
  CoreValuesSection,
  HeroTextGroup,
  MissionGrid,
  MissionGridContainer,
  // MissionImage,
  MissionText,
  // TeamGrid,
  // TeamSection,
  ValueTextGroup,
  ValuesGrid,
  VisionStatment,
} from "./style";
import {
  ABOUT_DETAILS,
  CORE_VALUES,
  // MISSION_STATEMENT,
  VISION_STATEMENT,
} from "src/constants/about";
// import { Shadow } from "src/components/ServiceRequest/style";
import {
  containerVariants,
  getScrollRevealProps,
  itemVariants,
} from "src/utils/framer-motion";
// import { TEAM_MEMBERS } from 'src/constants/team';
// import TeamMember from 'src/components/TeamMember';
import HeadingGroup from "src/components/HeadingGroup";

const About = () => {
  return (
    <Layout>
      <Hero>
        <AboutHeroContainer>
          <HeroTextGroup>
            <HeroHeading {...getScrollRevealProps("left")}>
              Who we are
            </HeroHeading>

            <HeroText {...getScrollRevealProps("left")}>
              {ABOUT_DETAILS.description}
            </HeroText>
          </HeroTextGroup>

          <AboutHeroImages
            variants={containerVariants}
            initial="hidden"
            whileInView="visible"
          >
            {ABOUT_DETAILS.images.map((image, i) => (
              <AboutImage
                key={i}
                src={image.src}
                alt={image.alt}
                variants={itemVariants}
              />
            ))}
          </AboutHeroImages>
        </AboutHeroContainer>
      </Hero>

      <MissionGridContainer>
        {/* <MissionGrid>
          <MissionText>
            <img
              src={MISSION_STATEMENT.icon}
              alt="our mission"
              width={90}
              height={90}
            />

            <h2>Our Mission</h2>

            <p>{MISSION_STATEMENT.text}</p>
          </MissionText>

          <MissionImage
            src={MISSION_STATEMENT.image.src}
            alt={MISSION_STATEMENT.image.alt}
          />
        </MissionGrid> */}

        <MissionGrid>
          {/* <MissionImage
            data-reverse
            src={VISION_STATEMENT.image.src}
            alt={VISION_STATEMENT.image.alt}
          /> */}

          <MissionText>
            <img
              src={VISION_STATEMENT.icon}
              alt="our vision"
              width={90}
              height={90}
            />

            <h2>Our Vision</h2>

            <VisionStatment>{VISION_STATEMENT.text}</VisionStatment>
          </MissionText>
        </MissionGrid>
      </MissionGridContainer>

      <CoreValuesSection>
        <HeadingGroup
          heading="Our core values"
          subheading="what we stand for at mephalti"
        />

        <ValuesGrid>
          {CORE_VALUES.map((value, i) => (
            <ValueTextGroup key={i}>
              <img
                src={value.icon}
                alt={value.heading}
                width={60}
                height={60}
              />
              <h3>{value.heading}</h3>
              <p>{value.details}</p>
            </ValueTextGroup>
          ))}
        </ValuesGrid>
      </CoreValuesSection>

      {/* TEAM MEMBERS SECTION */}

      {/* <TeamSection>
        <HeadingGroup
          heading="Meet the team"
          subheading="Meet the talented individuals behind our company
            each with a unique set of skills to drive our
            success."
        />

        <TeamGrid>
          {TEAM_MEMBERS.map((member, i) => (
            <TeamMember details={member} />
          ))}
        </TeamGrid>
      </TeamSection> */}
    </Layout>
  );
};

export default About;
