import Button from '../Button';
import Input from '../Input';
import {
  NewsletterFormProps,
  NewsletterSchema,
} from './schema';
import {
  Heading,
  InputGroup,
  NewsletterContainer,
  StyledSection,
  Text,
  TextGroup,
} from './style';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import newsletterService from 'src/network/services/newsletter-service';
import { toast } from 'react-hot-toast';
import { useState } from 'react';

const Newsletter = () => {
  const [isLoading, setLoading] = useState(false);

  const {
    handleSubmit,
    register,
    clearErrors,
    formState: { errors },
    reset,
  } = useForm<NewsletterFormProps>({
    resolver: zodResolver(NewsletterSchema),
  });

  const addToNewsletter = async (
    formData: NewsletterFormProps
  ) => {
    setLoading(true);

    try {
      const { status } = await newsletterService(formData);

      if (status === 201) {
        toast.success('Thanks for subscribing');

        reset();
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error('Something went wrong');

      console.log(
        'Newsletter Error :',
        JSON.stringify(error, null, 2)
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledSection>
      <NewsletterContainer>
        <TextGroup>
          <Heading>Subscribe to Our Newsletter</Heading>
          <Text>
            Subscribe to our newsletter to get the latest
            updates
          </Text>
        </TextGroup>

        <InputGroup
          onSubmit={handleSubmit(addToNewsletter)}
        >
          <Input
            type="email"
            placeholder="Email address"
            {...register('email')}
            error={!!errors.email}
            message={errors.email?.message}
            onChange={() => clearErrors()}
          />
          <Button type="submit" loading={isLoading} fluid>
            Subscribe
          </Button>
        </InputGroup>
      </NewsletterContainer>
    </StyledSection>
  );
};

export default Newsletter;
