import { JobDetails } from 'src/interfaces/constants';

export const JOB_DETAILS: JobDetails = {
  position: 'Front End developer',
  experience: '2 years',
  description: `Lorem ipsum dolor sit amet 
  consectetur. Risus amet lectus feugiat venenatis 
  sed ac massa nunc. Proin at dui aliquam sed commodo 
  sit. Proin at dui aliquam sed commodo sit. Lorem 
  ipsum dolor sit amet consectetur. `,

  sections: [
    {
      type: 'list',
      heading: 'What will you be doing ?',
      text: [
        `Lorem ipsum dolor sit amet 
        consectetur.`,
        `Lorem ipsum dolor sit amet.`,
        `Proin at dui aliquam sed commodo sit. Lorem 
        ipsum dolor sit amet consectetur.`,
        `Lorem ipsum dolor sit amet 
        consectetur.`,
        `Lorem ipsum dolor sit amet.`,
        `Proin at dui aliquam sed commodo sit. Lorem 
        ipsum dolor sit amet consectetur.`,
        `Lorem ipsum dolor sit amet 
        consectetur.`,
        `Lorem ipsum dolor sit amet.`,
        `Proin at dui aliquam sed commodo sit. Lorem 
        ipsum dolor sit amet consectetur.`,
      ],
    },
    {
      type: 'list',
      heading: 'Job requirements',
      text: [
        `Lorem ipsum dolor sit amet 
        consectetur.`,
        `Lorem ipsum dolor sit amet.`,
        `Proin at dui aliquam sed commodo sit. Lorem 
        ipsum dolor sit amet consectetur.`,
        `Lorem ipsum dolor sit amet 
        consectetur.`,
        `Lorem ipsum dolor sit amet.`,
        `Proin at dui aliquam sed commodo sit. Lorem 
        ipsum dolor sit amet consectetur.`,
      ],
    },
    {
      type: 'list',
      heading: 'What we offer',
      text: [
        `Lorem ipsum
        consectetur.`,
        `Lorem ipsum amet.`,
        `Proin at dui aliquam sed.`,
      ],
    },
    {
      type: 'text',
      heading: 'Additional infomation',
      text: `Lorem ipsum dolor sit amet 
      consectetur.`,
    },
  ],
};
