import Layout from 'src/components/Layout';
import { Hero, HeroTextGroup } from '../CareersApply/style';
import { HeroHeading } from '../Landing/style';
import Button from 'src/components/Button';
import { PhoneIcon } from 'src/components/Icons';
import { ContactFormContainer } from '../Contact/style';
import ServiceRequestForm from 'src/components/ServiceRequestForm';

const ServiceRequestPage = () => {
  return (
    <Layout>
      <Hero>
        <HeroTextGroup>
          <HeroHeading>Request a Service</HeroHeading>
          <p>
            Complete the form below with the details of the
            service you require or schedule a call with us
            to discuss further
          </p>
          <Button
            asLink
            target="_blank"
            rel="nooponer noreferer"
            href="https://calendly.com/mephaltihq/30min"
          >
            Schedule a call
            <PhoneIcon />
          </Button>
        </HeroTextGroup>
      </Hero>

      <ContactFormContainer>
        <ServiceRequestForm />
      </ContactFormContainer>
    </Layout>
  );
};

export default ServiceRequestPage;
