import {
  MessageIcon,
  PhoneIcon,
} from 'src/components/Icons';
import contactImg from '../assets/contactImg.png';

export const CONTACT_LINKS = [
  {
    icon: <PhoneIcon />,
    text: '+234 903 579 9508',
    url: 'tel:+2349035799508',
  },
  {
    icon: <MessageIcon />,
    text: 'info@mephalti.com',
    url: 'mailto:info@mephalti.com',
  },
];

export const CONTACT_DETAILS = {
  image: {
    src: contactImg,
    alt: 'contact us',
  },
  text: `If you need any further assistance 
  or have additional questions, feel free 
  to reach out to us.`,
  links: CONTACT_LINKS,
};
