import { Container } from "src/components/Container";
import styled from "styled-components";

export const CareersHero = styled.section`
  padding-block: 64px;
  padding-bottom: 100px;
  background-color: ${(props) => props.theme.colors.brand.dark};

  @media (max-width: 767px) {
    padding-block: 34px;
    padding-bottom: 100px;
  }
`;

export const CarrersHeroContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 64px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const TextGroup = styled.div`
  color: ${(props) => props.theme.colors.gray[400]};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  a {
    margin-top: 40px;
  }

  @media (max-width: 767px) {
    text-align: center;
    align-items: center;
  }
`;

export const CareersImgWrapper = styled.div`
  position: relative;
  z-index: 1;

  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    right: -26px;
    bottom: -20px;
    z-index: -1;
    border: 2px solid ${(props) => props.theme.colors.brand.accent};
    border-radius: 16px;
  }

  @media (max-width: 579px) {
    &::after {
      right: -12px;
      bottom: -10px;
    }
  }
`;

export const CareersImage = styled.img`
  width: 100%;
  height: 460px;
  object-fit: cover;
  object-position: top;
  border-radius: 16px;

  @media (max-width: 579px) {
    height: 340px;
  }
`;

export const OfferSection = styled.section`
  padding-block: 100px;
  background-color: ${(props) => props.theme.colors.lightBlue[200]};

  @media (max-width: 767px) {
    padding-block: 50px;
  }
`;

export const PositionsSection = styled.section`
  padding-block: 100px;
  display: grid;
  gap: 64px;

  h2 {
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;
    width: max-content;
    max-width: ${(props) => props.theme.width.container};
    margin-inline: auto;
  }

  p {
    text-align: center;
  }

  @media (max-width: 767px) {
    padding-block: 50px;
  }

  @media (max-width: 579px) {
    gap: 34px;
  }
`;

export const JobTitle = styled.a`
  color: #02001e;
  font-size: 20px;
  font-weight: 700;
  box-shadow: 0 0 30px rgb(0, 0, 0, 0.2);
  padding: 15px 10px 15px 10px;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  margin: 20px;
  text-align: center;
  :hover {
    box-shadow: 0 0 30px rgb(0, 0, 0, 0.7);
    transform: scale(1.1);
    transition-duration: 0.6s;
  }

  @media (min-width: 600px) {
    font-weight: 500;
    font-size: 15px;
    width: 50%;
    border-radius: 10px;
  }

  @media (max-width: 600px) {
    font-weight: 500;
    font-size: 15px;
    width: 70%;
    border-radius: 10px;
  }
`;

export const JobTitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
