import { useState, useEffect } from 'react';

type ScrollDirection = 'up' | 'down';

const useScrollDirection = (): ScrollDirection | null => {
  const [scrollDirection, setScrollDirection] =
    useState<ScrollDirection | null>(null);

  useEffect(() => {
    let lastScrollTop =
      window.scrollY || document.documentElement.scrollTop;
    let ticking = false;

    function handleScroll() {
      const currentScrollTop =
        window.scrollY ||
        document.documentElement.scrollTop;

      if (!ticking) {
        window.requestAnimationFrame(() => {
          if (currentScrollTop > lastScrollTop) {
            setScrollDirection('down');
          } else if (currentScrollTop < lastScrollTop) {
            setScrollDirection('up');
          }
          lastScrollTop = currentScrollTop;
          ticking = false;
        });

        ticking = true;
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return scrollDirection;
};

export default useScrollDirection;
