import { SwiperProps } from 'swiper/react';
import { A11y, Navigation, Autoplay } from 'swiper';

export const ServicesSlideProps: SwiperProps = {
  modules: [A11y, Navigation, Autoplay],
  spaceBetween: 20,
  slidesPerView: 1,
  // loop: true,
  autoplay: {
    delay: 8000,
    pauseOnMouseEnter: true,
    disableOnInteraction: false,
  },
  breakpoints: {
    767: {
      slidesPerView: 2,
      spaceBetween: 30,
    },
    1125: {
      slidesPerView: 3,
      spaceBetween: 40,
    },
  },
  navigation: true,
};

export const TestimonialSlideProps: SwiperProps = {
  modules: [A11y],
  loop: true,
};
