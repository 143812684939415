import { Container } from 'src/components/Container';
import styled from 'styled-components';

export const ContactFormContainer = styled(Container)`
  padding-block: 50px;
`;

export const ContactCard = styled.section`
  margin-block: 64px;
  width: ${props => props.theme.width.container};
  margin-inline: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 44px;
  background-color: ${props =>
    props.theme.colors.brand.dark};

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    padding: 2rem;
  }
`;

export const ContactCardImage = styled.img`
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-radius: 64px 0px;

  @media (max-width: 767px) {
    height: 340px;
  }
  @media (max-width: 579px) {
    height: 240px;
  }
`;

export const ContactCardText = styled.div`
    color: ${(props) => props.theme.colors.gray[400]};
  align-self: center;
  display: grid;
  gap: 40px;

  h3 {
    font-size: 32px;
    line-height: 48px;

    @media (max-width: 1023px) {
      font-size: 24px;
    }

    @media (max-width: 579px) {
      font-size: 18px;
      line-height: 1.5;
    }
  }

  ul {
    display: grid;
    gap: 25px;
  }

  a {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
