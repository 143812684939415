import { Spinner, StyledButton } from './style';

import { HTMLMotionProps, motion } from 'framer-motion';

interface ButtonProps extends HTMLMotionProps<'button'> {
  children: React.ReactNode;
  variant?: 'primary' | 'secondary' | 'outlined' | 'ghost';
  fluid?: boolean;
  asLink?: boolean;
  href?: string;
  target?: string;
  rel?: string;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'primary',
  fluid = false,
  asLink = false,
  href = '/',
  target,
  rel,
  loading = false,

  ...props
}) => {
  return asLink ? (
    <StyledButton
      as={motion.a}
      href={href}
      target={target}
      rel={rel}
      data-variant={variant}
      data-fluid={fluid}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ scale: 0.5, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      {children}
    </StyledButton>
  ) : (
    <StyledButton
      data-variant={variant}
      data-fluid={fluid}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      initial={{ scale: 0.5, opacity: 0 }}
      whileInView={{ scale: 1, opacity: 1 }}
      transition={{ duration: 0.4 }}
      {...props}
    >
      {loading ? <Spinner /> : children}
    </StyledButton>
  );
};

export default Button;
