type IconProps = React.SVGAttributes<SVGElement>;

export const RightArrow: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.5 12H19.5"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 5L19.5 12L12.5 19"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownArrow: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 5V19"
      stroke="#F8FAFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19 12L12 19L5 12"
      stroke="#F8FAFC"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DoubleCheckMark: React.FC<
  IconProps
> = props => (
  <svg
    {...props}
    width="20"
    height="14"
    viewBox="0 0 20 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.6471 13.01L4.6971 8.06099L6.1101 6.64599L9.6471 10.183L18.1321 1.69699L19.5461 3.11099L9.6461 13.011L9.6471 13.01ZM5.4041 13.01L0.454102 8.06099L1.8681 6.64599L6.8181 11.596L5.4051 13.01H5.4041ZM9.6471 8.76799L8.2321 7.35399L14.5961 0.98999L16.0111 2.40399L9.6471 8.76699V8.76799Z"
      fill="#003166"
    />
  </svg>
);

export const LeftChevron: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 13L1 7L7 1"
      stroke="#02001E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const RightChevron: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="8"
    height="14"
    viewBox="0 0 8 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L7 7L1 13"
      stroke="#02001E"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DocumentCode: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35 11.6667V28.3334C35 33.3334 32.5 36.6667 26.6667 36.6667H13.3333C7.5 36.6667 5 33.3334 5 28.3334V11.6667C5 6.66671 7.5 3.33337 13.3333 3.33337H26.6667C32.5 3.33337 35 6.66671 35 11.6667Z"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.167 7.5V10.8333C24.167 12.6667 25.667 14.1667 27.5003 14.1667H30.8337"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.6663 21.6666L13.333 25L16.6663 28.3333"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.333 21.6666L26.6663 25L23.333 28.3333"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const StackIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 28.3334L20.6667 36.6667L37.3333 28.3334"
      stroke="#02001E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 20L20.6667 28.3333L37.3333 20"
      stroke="#02001E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.6667 3.33337L4 11.6667L20.6667 20L37.3333 11.6667L20.6667 3.33337Z"
      stroke="#02001E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const BlockchainIcon: React.FC<
  IconProps
> = props => (
  <svg
    {...props}
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M35.333 26.6666V13.3333C35.3324 12.7487 35.1781 12.1746 34.8856 11.6685C34.593 11.1624 34.1726 10.7422 33.6663 10.4499L21.9997 3.78325C21.4929 3.49069 20.9181 3.33667 20.333 3.33667C19.7479 3.33667 19.1731 3.49069 18.6663 3.78325L6.99967 10.4499C6.49345 10.7422 6.07297 11.1624 5.78044 11.6685C5.48791 12.1746 5.33361 12.7487 5.33301 13.3333V26.6666C5.33361 27.2511 5.48791 27.8252 5.78044 28.3313C6.07297 28.8374 6.49345 29.2576 6.99967 29.5499L18.6663 36.2166C19.1731 36.5091 19.7479 36.6632 20.333 36.6632C20.9181 36.6632 21.4929 36.5091 21.9997 36.2166L33.6663 29.5499C34.1726 29.2576 34.593 28.8374 34.8856 28.3313C35.1781 27.8252 35.3324 27.2511 35.333 26.6666Z"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M35.333 20L27.833 24.3333V32.9833"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.833 32.9833V24.3333L5.33301 20"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.7832 11.6001L20.3332 20.0168L34.8832 11.6001"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.333 36.8V20"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.833 7.0166L20.333 11.3499L27.833 7.0166"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const ChartIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="41"
    height="40"
    viewBox="0 0 41 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5004 28.3333H30.8171C33.9837 28.3333 35.6504 26.6666 35.6504 23.4999V3.33325H5.65039V23.4999C5.66706 26.6666 7.33373 28.3333 10.5004 28.3333Z"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 3.33325H37.3333"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 36.6666L20.6667 33.3333V28.3333"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.3337 36.6666L20.667 33.3333"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.167 18.3333L18.417 13.9499C18.8336 13.5999 19.3836 13.6999 19.667 14.1666L21.667 17.4999C21.9503 17.9666 22.5003 18.0499 22.917 17.7166L28.167 13.3333"
      stroke="#02001E"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MiniusIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="16"
    height="2"
    viewBox="0 0 16 2"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1H15"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PlusIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 12H18.5"
      stroke="#02001E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 18V6"
      stroke="#02001E"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DownChevron: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 9L12 15L18 9"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const SearchIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.0004 20.9999L16.6504 16.6499"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DesktopIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.333 35H26.6663"
      stroke="#02001E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 28.3333V34.9999"
      stroke="#02001E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M33.333 5H6.66634C4.82539 5 3.33301 6.49238 3.33301 8.33333V25C3.33301 26.841 4.82539 28.3333 6.66634 28.3333H33.333C35.174 28.3333 36.6663 26.841 36.6663 25V8.33333C36.6663 6.49238 35.174 5 33.333 5Z"
      stroke="#02001E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MobileIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.333 3.33337H11.6663C9.82539 3.33337 8.33301 4.82576 8.33301 6.66671V33.3334C8.33301 35.1743 9.82539 36.6667 11.6663 36.6667H28.333C30.174 36.6667 31.6663 35.1743 31.6663 33.3334V6.66671C31.6663 4.82576 30.174 3.33337 28.333 3.33337Z"
      stroke="#02001E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20 30H20.0167"
      stroke="#02001E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UpChevron: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 15L12 9L6 15"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const LocationIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 10C21 17 12 23 12 23C12 23 3 17 3 10C3 7.61305 3.94821 5.32387 5.63604 3.63604C7.32387 1.94821 9.61305 1 12 1C14.3869 1 16.6761 1.94821 18.364 3.63604C20.0518 5.32387 21 7.61305 21 10Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const UploadIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_65_13395)">
      <path
        d="M32 32L24 24L16 32"
        stroke="#02001E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24 24V42"
        stroke="#02001E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M40.7799 36.78C42.7306 35.7165 44.2716 34.0337 45.1597 31.9972C46.0477 29.9607 46.2323 27.6864 45.6843 25.5333C45.1363 23.3803 43.8869 21.471 42.1333 20.1069C40.3796 18.7427 38.2216 18.0014 35.9999 18H33.4799C32.8746 15.6585 31.7462 13.4846 30.1798 11.642C28.6134 9.79927 26.6496 8.33567 24.4361 7.36118C22.2226 6.3867 19.817 5.92669 17.4002 6.01573C14.9833 6.10478 12.6181 6.74057 10.4823 7.8753C8.34649 9.01003 6.49574 10.6142 5.06916 12.5671C3.64259 14.5201 2.6773 16.771 2.24588 19.1508C1.81446 21.5305 1.92813 23.977 2.57835 26.3065C3.22856 28.6359 4.3984 30.7877 5.99992 32.6"
        stroke="#02001E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32 32L24 24L16 32"
        stroke="#02001E"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_65_13395">
        <rect width="48" height="48" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CloseIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18 6L6 18"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 6L18 18"
      stroke="#0F172A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PhoneIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.0004 15.9201V18.9201C21.0016 19.1986 20.9445 19.4743 20.8329 19.7294C20.7214 19.9846 20.5577 20.2137 20.3525 20.402C20.1473 20.5902 19.905 20.7336 19.6412 20.8228C19.3773 20.912 19.0978 20.9452 18.8204 20.9201C15.7433 20.5857 12.7874 19.5342 10.1904 17.8501C7.77425 16.3148 5.72576 14.2663 4.19042 11.8501C2.5004 9.2413 1.44866 6.27109 1.12042 3.1801C1.09543 2.90356 1.1283 2.62486 1.21692 2.36172C1.30555 2.09859 1.44799 1.85679 1.63519 1.65172C1.82238 1.44665 2.05023 1.28281 2.30421 1.17062C2.5582 1.05843 2.83276 1.00036 3.11042 1.0001H6.11042C6.59573 0.995321 7.06621 1.16718 7.43418 1.48363C7.80215 1.80008 8.0425 2.23954 8.11042 2.7201C8.23704 3.68016 8.47187 4.62282 8.81042 5.5301C8.94497 5.88802 8.97408 6.27701 8.89433 6.65098C8.81457 7.02494 8.62928 7.36821 8.36042 7.6401L7.09042 8.9101C8.51398 11.4136 10.5869 13.4865 13.0904 14.9101L14.3604 13.6401C14.6323 13.3712 14.9756 13.1859 15.3495 13.1062C15.7235 13.0264 16.1125 13.0556 16.4704 13.1901C17.3777 13.5286 18.3204 13.7635 19.2804 13.8901C19.7662 13.9586 20.2098 14.2033 20.527 14.5776C20.8441 14.9519 21.0126 15.4297 21.0004 15.9201Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MessageIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 4H20C21.1 4 22 4.9 22 6V18C22 19.1 21.1 20 20 20H4C2.9 20 2 19.1 2 18V6C2 4.9 2.9 4 4 4Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M22 6L12 13L2 6"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const GlobeIcon: React.FC<IconProps> = props => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2 12H22"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
