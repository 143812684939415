import Layout from 'src/components/Layout';
import { Hero, HeroTextGroup } from '../CareersApply/style';
import { HeroHeading } from '../Landing/style';
import {
  ContactCard,
  ContactCardImage,
  ContactCardText,
  ContactFormContainer,
} from './style';
import ContactForm from 'src/components/ContactForm';
import { CONTACT_DETAILS } from 'src/constants/contact';

const ContactPage = () => {
  return (
    <Layout>
      <Hero>
        <HeroTextGroup>
          <HeroHeading>Contact Us</HeroHeading>
          <p>
            Have a question? Fill the form below to reach
            out to us or contact us via our various contact
            lines
          </p>
        </HeroTextGroup>
      </Hero>

      <ContactFormContainer>
        <ContactForm />
      </ContactFormContainer>

      <ContactCard>
        <ContactCardImage
          src={CONTACT_DETAILS.image.src}
          alt={CONTACT_DETAILS.image.alt}
        />
        <ContactCardText>
          <h3>{CONTACT_DETAILS.text}</h3>

          <ul>
            {CONTACT_DETAILS.links.map((link, i) => (
              <li key={i}>
                <a href={link.url}>
                  <span>{link.icon}</span>
                  <span>{link.text}</span>
                </a>
              </li>
            ))}
          </ul>
        </ContactCardText>
      </ContactCard>
    </Layout>
  );
};

export default ContactPage;
