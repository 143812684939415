import styled from 'styled-components';

export const UploadFileWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 2rem;
  width: 100%;
  height: 300px;
  border-radius: ${props => props.theme.radius.lg};
  border: 2px dashed
    ${props => props.theme.colors.brand.dark};
  background-color: ${({ theme }) => theme.colors.gray[50]};
  display: grid;
  place-items: center;
  text-align: center;
  transition: 150ms;

  &[data-active='true'] {
    border-color: ${({ theme }) =>
      theme.colors.brand.accent};
  }
`;

export const CustomFileInput = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  appearance: none;
  opacity: 0;
  cursor: pointer;
`;

export const ImageIcon = styled.div`
  font-size: 1.2rem;
`;

export const Label = styled.label`
  font-size: 1.2rem;
`;

export const Text = styled.p`
  color: ${({ theme }) => theme.colors.gray[300]};
  word-break: break-all;
  padding: 2rem;

  span {
    font-weight: 700;
  }
`;

export const Preview = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fffffff9;
  display: grid;
  place-items: center;

  button {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

export const FileImagePreview = styled.img`
  object-fit: contain;
  height: 100%;
  max-height: 300px;
  width: 100%;
  margin-inline: auto;
  padding: 2rem;
`;
