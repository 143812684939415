import { useCallback, useEffect, useState } from 'react';

type Handler = () => void;

const useScroll = (handler?: Handler) => {
  const [scrollHeight, setScrollHeight] = useState(0);

  const listener = useCallback(() => {
    if (handler) {
      handler();
    }

    setScrollHeight(window.scrollY);
  }, [handler]);

  useEffect(() => {
    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, [handler, listener]);

  return scrollHeight;
};

export default useScroll;
