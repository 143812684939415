import { motion } from 'framer-motion';
import { Container } from 'src/components/Container';
import styled from 'styled-components';

export const ProjectsHero = styled.section`
  padding-block: 80px;
  background-color: ${props =>
    props.theme.colors.brand.dark};
`;

export const ProjectsHeroContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 74px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

export const TextGroup = styled(motion.div)`
  color: ${(props) => props.theme.colors.gray[400]};
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const HeroImage = styled.img`
  width: 100%;
  height: 420px;
  object-fit: cover;
  border-radius: 8px 64px;

  @media (max-width: 579px) {
    height: 320px;
  }
`;

export const Projects = styled(Container)`
  display: grid;
`;
