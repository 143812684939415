import { motion } from "framer-motion";
import styled from "styled-components";

export const TextGroup = styled(motion.div)`
  max-width: 898px;
  margin-inline: auto;
  margin-bottom: ${(props) => props.theme.spacing[5]};
  color: ${(props) => props.theme.colors.gray[400]};

  @media (max-width: 767px) {
    text-align: center;
  }
`;

export const HeroHeading = styled(motion.h1)`
  font-weight: 800;
  line-height: 80px;
  font-size: ${(props) => props.theme.sizes.heading1};
  margin-bottom: ${(props) => props.theme.spacing[3]};

  @media (max-width: 1023px) {
    font-size: ${(props) => props.theme.sizes.heading2};
  }

  @media (max-width: 767px) {
    font-size: ${(props) => props.theme.sizes.heading3};
    line-height: 60px;
  }

  @media (max-width: 579px) {
    font-size: ${(props) => props.theme.sizes.heading4};
    line-height: 52px;
  }
`;

export const HeroText = styled(motion.p)`
  max-width: 840px;
  margin-inline: auto;
  font-size: ${(props) => props.theme.sizes.lg};
  line-height: 32px;
`;
