import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledServiceCard = styled(motion.article)`
  padding: ${props => props.theme.spacing[4]};
  border-radius: ${props => props.theme.radius.xl};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 38px 84px -16px rgba(217, 215, 219, 0.437);
  height: 100%;

  width: calc(
    100% - 1px
  ); /* (100% - 1px) allows border right to show in swiper slider */

  border: 1px solid ${props => props.theme.colors.gray[100]};

  button {
    margin-top: 1rem;
    color: ${p => p.theme.colors.brand.accent};
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: 220px;
  object-fit: cover;
  border-radius: ${props => props.theme.radius.xl};
`;

export const CardHeading = styled(motion.h3)`
  margin-top: ${props => props.theme.spacing[3]};
  font-weight: 700;
  font-size: ${props => props.theme.sizes.xl};
  color: ${props => props.theme.colors.black};
`;

export const CardText = styled(motion.p)`
  margin-top: ${props => props.theme.spacing[3]};
  color: ${props => props.theme.colors.gray[300]};
  font-size: ${props => props.theme.sizes.lg};
`;
