import axios from 'axios';
import { NEWSLETTER } from '../apiRoutes';
import { NewsletterFormProps } from 'src/components/Newsletter/schema';

const newsletterService = async (
  payload: NewsletterFormProps
) => {
  const { status } = await axios.post(NEWSLETTER, payload);

  return { status };
};

export default newsletterService;
