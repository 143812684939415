import styled from 'styled-components';
import { Container } from '../Container';
import { motion } from 'framer-motion';

export const ServiceRequestSection = styled.section`
  padding-bottom: 100px;

  /* @media (max-width: 767px) {
    padding-top: 80px;
  } */
`;

export const ServiceRequestContainer = styled(Container)`
  display: grid;
  place-items: center;
  text-align: center;
  gap: 24px;
`;

export const Heading = styled(motion.h2)`
  font-size: ${props => props.theme.sizes.heading3};
  font-weight: 700;
  line-height: 58px;

  @media (max-width: 579px) {
    font-size: ${props => props.theme.sizes.heading4};
  }
`;

export const Text = styled(motion.p)`
  color: #36454f;
  font-size: ${props => props.theme.sizes.lg};
  margin-bottom: 1rem;
  line-height: 32px;

  @media (max-width: 579px) {
    font-size: ${props => props.theme.sizes.base};
  }
`;

export const Accent = styled.span`
  font-family: Larsseit;
  font-size: 3rem;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: ${p => p.theme.colors.brand.accent};
`;
