import axios from 'axios';
import { CONTACT } from '../apiRoutes';
import { ServiceRequestFormProps } from 'src/components/ServiceRequestForm/schema';

type Payload = ServiceRequestFormProps & {
  requestedServices: string[];
};

const serviceRequestService = async (payload: Payload) => {
  const { status, data } = await axios.post(CONTACT, {
    ...payload,
    subject: 'Service Request',
  });

  return { status, data };
};

export default serviceRequestService;
