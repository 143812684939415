import Layout from "src/components/Layout";
import {
  CareersHero,
  CareersImage,
  CareersImgWrapper,
  CarrersHeroContainer,
  OfferSection,
  PositionsSection,
  TextGroup,
  JobTitle,
  JobTitleContainer,
} from "./style";
import { HeroHeading, HeroText } from "../Landing/style";
import { CAREERS_DETAILS, OUR_OFFERS } from "src/constants/careers";
import Button from "src/components/Button";
// import OpenPositions from 'src/components/OpenPositions';
// import SearchPositions from 'src/components/SearchPositions';
import HeadingGroup from "src/components/HeadingGroup";
import { ValueTextGroup, ValuesGrid } from "../About/style";
import { Container } from "src/components/Container";

const CareersPage = () => {
  return (
    <Layout>
      <CareersHero>
        <CarrersHeroContainer>
          <TextGroup>
            <HeroHeading>{CAREERS_DETAILS.heading}</HeroHeading>

            <HeroText>{CAREERS_DETAILS.text}</HeroText>

            <Button asLink href="#openPositions">
              {CAREERS_DETAILS.cta}
            </Button>
          </TextGroup>

          <CareersImgWrapper>
            <CareersImage
              src={CAREERS_DETAILS.image.src}
              alt={CAREERS_DETAILS.image.alt}
            />
          </CareersImgWrapper>
        </CarrersHeroContainer>
      </CareersHero>

      <OfferSection>
        <HeadingGroup
          heading="What we offer"
          subheading="Why you should join our team"
        />

        <ValuesGrid>
          {OUR_OFFERS.map((offer, i) => (
            <ValueTextGroup key={i}>
              <img
                src={offer.icon}
                alt={offer.heading}
                width={60}
                height={60}
              />
              <h3>{offer.heading}</h3>
              <p>{offer.details}</p>
            </ValueTextGroup>
          ))}
        </ValuesGrid>
      </OfferSection>

      <PositionsSection id="openPositions">
        <h2>Open positions</h2>

        {/* <SearchPositions /> */}

        <Container>
          <JobTitleContainer>
            <JobTitle href="https://forms.gle/dqGXmYtDH4vLsCZ26">
              Financial Officer
            </JobTitle>
            {/* <JobTitle href='https://forms.gle/qvUsHPE5mD95YmuT7'>Business Development Officer</JobTitle>
            <JobTitle href='https://forms.gle/fwCV8vFKce7CCjqj6'>Social Media Manager</JobTitle>  */}
          </JobTitleContainer>
        </Container>

        {/* <OpenPositions /> */}
      </PositionsSection>
    </Layout>
  );
};

export default CareersPage;
