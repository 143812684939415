import { ImageList, TestimonialCard } from 'src/interfaces/constants';

import hero1 from '../assets/hero1.png';
import hero2 from '../assets/hero2.png';
import hero3 from '../assets/hero3.png';
import service4 from '../assets/service4.jpg';

import avatarMain from '../assets/avatar/avatar-main.jpg';
import avatar1 from '../assets/avatar/avatar1.jpg';
import avatar2 from '../assets/avatar/avatar2.jpg';
import avatar3 from '../assets/avatar/avatar3.jpg';
import avatar4 from '../assets/avatar/avatar4.jpg';
import avatar5 from '../assets/avatar/avatar5.jpg';
import avatar6 from '../assets/avatar/avatar6.jpg';

export const HERO_DETAILS = {
  // heading: `Transforming business and individual experiences through innovation`,
  heading: `Changing the world, product by product.`,

  // heading: `We offer comprehensive solution across the petroleum and tech Industries`,
  // text: `We offer robust IoT-enabled virtual marketplace, empowering individuals and businesses to monitor and track petroleum product (such as Cooking Gas, AGO, PMS etc.)  levels and movement in real-time, streamline purchases, and efficiently manage inventory and energy consumption.`,
  // text: "We offer a robust IoT-enabled virtual marketplace, empowering individuals and businesses to monitor and track petroleum products like cooking gas, AGO, and PMS in real-time, streamline purchases, and efficiently manage inventory and energy consumption.",
  // text: "Empowering innovation, one product at a time",
  text: 'Fostering innovation, one product at a time.',
  // cta: "Request for a service",
  cta: 'Our Porfolio',
};

export const HERO_IMAGES: ImageList = [
  {
    alt: 'test1',
    src: hero2,
  },
  {
    alt: 'test2',
    src: hero1,
  },
  {
    alt: 'test3',
    src: hero3,
  },
];

export const SERVICES_DETAILS = {
  heading:
    'We specialize in custom software development, cloud-based solutions, e-commerce solutions, and digital transformation services.',
  subHeading: `We want to expand your business visibility.`,
  text: ``,

  banner: {
    image: service4,
    heading: `Dedicated to providing you with the best services`,
    services: [
      'Fast and reliable service',
      'Quality and results',
      'Fast and reliable service',
    ],
  },
};

export const PORTFOLIO_DETAILS = {
  heading: 'Our Portfolio',
};

export const TESTIMONIAL_DETAILS = {
  heading: 'Our client testimonials',
  subheading: 'what our happy clients say about us',

  avatars: [
    {
      title: 'test',
      image: avatar1,
    },
    {
      title: 'test2',
      image: avatar2,
    },
    {
      title: 'test3',
      image: avatar3,
    },
    {
      title: 'test4',
      image: avatar4,
    },
    {
      title: 'test5',
      image: avatar5,
    },
    {
      title: 'test6',
      image: avatar6,
    },
  ],
};

export const TESTIMONIAL_CARDS: TestimonialCard[] = [
  {
    image: avatarMain,
    name: 'Jacob Jones',
    title: 'Digital Marketer',
    text: ` "Lorem ipsum dolor sit amet consectetur adipisicing
    elit. Nostrum facilis magni totam optio repudiandae
    adipisci eum nisi mollitia vero? Error repellendus
    eveniet deserunt iste nemo, adipisci minus officiis
    exercitationem hic asperiores quaerat consequuntur
    dignissimos natus!"`,
  },
  {
    image: avatarMain,
    name: 'James Jones',
    title: 'Frontend Developer',
    text: ` "Lorem ipsum dolor sit amet consectetur adipisicing
    elit. Nostrum facilis magni totam optio repudiandae
    adipisci eum nisi mollitia vero? Error repellendus
    eveniet deserunt iste nemo, adipisci minus officiis
    exercitationem hic asperiores quaerat consequuntur
    dignissimos natus!"`,
  },
  {
    image: avatarMain,
    name: 'Patrick Jones',
    title: 'Backend Developer',
    text: ` "Lorem ipsum dolor sit amet consectetur adipisicing
    elit. Nostrum facilis magni totam optio repudiandae
    adipisci eum nisi mollitia vero? Error repellendus
    eveniet deserunt iste nemo, adipisci minus officiis
    exercitationem hic asperiores quaerat consequuntur
    dignissimos natus!"`,
  },
];
