import axios from 'axios';
import { CONTACT } from '../apiRoutes';
import { ContactFormProps } from 'src/components/ContactForm/schema';

const contactService = async (
  payload: ContactFormProps
) => {
  const { status, data } = await axios.post(
    CONTACT,
    payload
  );

  return { status, data };
};

export default contactService;
