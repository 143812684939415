import styled from 'styled-components';

export const InterestedServicesWrapper = styled.div`
  display: grid;
  gap: 1rem;

  h3 {
    font-weight: 500;
  }
`;

export const InterestedServices = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;
