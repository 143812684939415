import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledPortolioCard = styled(motion.article)`
  border-radius: ${props => props.theme.radius.lg};
  background-color: ${props => props.theme.colors.white};
  box-shadow: 0px 38px 84px -16px rgba(217, 215, 219, 0.437);
  height: 100%;
  flex: 1;
  position: relative;

  width: calc(
    100% - 1px
  ); /* (100% - 1px) allows border right to show in swiper slider */

  button {
    margin-top: 1rem;
  }
`;

export const PortfolioCardImage = styled.img`
  width: 100%;
  max-height: 34.75rem;
  object-fit: cover;
  border-radius: ${props => props.theme.radius.xl};
`;

export const CardHeading = styled(motion.h3)`
  margin-top: ${props => props.theme.spacing[3]};
  font-weight: 700;
  font-size: ${props => props.theme.sizes.xl};
  color: ${props => props.theme.colors.black};
`;

export const CardText = styled(motion.p)`
  margin-top: ${props => props.theme.spacing[3]};
  color: ${props => props.theme.colors.gray[300]};
  font-size: ${props => props.theme.sizes.lg};
`;

export const ButtonText = styled(motion.p)`
  font-weight: 500;
  font-size: ${props => props.theme.sizes.lg};
`;

export const ButtonWrapper = styled(motion.div)`
  /* margin-left: ${props => props.theme.spacing[8]}; */

  a {
    min-width: max-content;
  }

  svg {
    width: auto !important;
  }
`;

export const PorfolioCardWrapper = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  place-items: center;
  width: 100%;
  height: 100%;

  margin-top: ${props => props.theme.spacing[1]};
  /* left: 30%;  */
  /* transform: translateX(-35%);  */
  position: absolute;
  left: 0;
  right: 0;
  color: ${props => props.theme.colors.gray[300]};

  svg {
    width: 250px;

    @media (min-width: 900px) {
      width: 200px;
    }
  }
`;
