import careersHeroImg from '../assets/careersHeroImg.png';
import launch from '../assets/icons/launch2.png';
import meeting from '../assets/icons/meeting.png';
import startup from '../assets/icons/startup.png';
import homeWork from '../assets/icons/working-at-home.png';
import money from '../assets/icons/money.png';
import development from '../assets/icons/development.png';

export const CAREERS_DETAILS = {
  heading: 'Work at Mephalti',
  text: `At Mephalti, we're more than just a software 
  development company. We're a team of passionate and 
  dedicated individuals who are committed to delivering 
  high-quality software solutions to our clients.`,
  cta: 'Join the team',
  image: {
    src: careersHeroImg,
    alt: 'work at mephalti',
  },
};

export const OUR_OFFERS = [
  {
    icon: launch,
    heading: 'Exciting projects',
    details: `Work on exciting projects, 
    from mobile apps to web applications 
    and everything in between.`,
  },
  {
    icon: meeting,
    heading: 'Collaborative environment',
    details: `We foster an environment where everyone's 
    input is valued as the best ideas come from collaboration`,
  },
  {
    icon: startup,
    heading: 'Professional growth',
    details: `We offer training facilities, so you can 
    stay up-to-date with the best practices in the industry.`,
  },
  {
    icon: homeWork,
    heading: 'Work life balance',
    details: `We offer flexible work hours so 
    you can work in a way that suits your lifestyle.`,
  },
  {
    icon: money,
    heading: 'Competitive compensation',
    details: `We offer competitive compensation 
    benefits, like health insurance, paid time off, and more`,
  },
  {
    icon: development,
    heading: 'Cutting - edge technologies',
    details: `Opportunity to work with cutting-edge 
    technologies and learn new skills that will advance your career.`,
  },
];
