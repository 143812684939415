import { motion } from 'framer-motion';
import styled from 'styled-components';

export const StyledButton = styled(motion.button)`
  font-style: normal !important;
  padding: 12px 32px;
  border-radius: ${props => props.theme.radius.md};
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  line-height: ${props => props.theme.lineHight.xl};

  &[data-fluid='true'] {
    width: 100%;
  }

  &[data-variant='ghost'] {
    padding: 0;
  }
  &[data-variant='primary'] {
    background-color: ${props =>
      props.theme.colors.brand.accent};
    color: ${props => props.theme.colors.white};
  }

  &[data-variant='secondary'] {
    background-color: ${props =>
      props.theme.colors.brand.dark};
    color: ${props => props.theme.colors.white};
  }

  &[data-variant='outlined'] {
    background-color: transparent;
    color: ${props => props.theme.colors.brand.dark};
    border: 1px solid
      ${props => props.theme.colors.brand.dark};
  }
`;

export const ButtonLink = styled(motion.a)`
  text-decoration: none;
`;

export const Spinner = styled.span`
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 2px solid ${props => props.theme.colors.white};
  border-top-color: ${props =>
    props.theme.colors.brand.dark};
  border-radius: 50%;
  animation: rotate 500ms linear infinite;

  @keyframes rotate {
    to {
      transform: rotate(360deg);
    }
  }
`;
