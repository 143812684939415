import Layout from 'src/components/Layout';
import {
  // Faq,
  // FaqSection,
  ServiceHeading,
  ServiceHeroImg,
  ServicesGrid,
  ServicesHero,
  ServicesHeroContainer,
  ServicesSection,
} from './style';
import HeroTextGroup from 'src/components/HeroTextGroup';
import { SERVICES_HERO, SERVICE_CARDS } from 'src/constants/services';
import ServicePageCard from 'src/components/ServicePageCard';
import { Accent } from 'src/components/ServiceRequest/style';
// import HeadingGroup from 'src/components/HeadingGroup';
// import { FAQS } from 'src/constants/faqs';
// import Accordion from 'src/components/Accordion';

const ServicesPage = () => {
  return (
    <Layout>
      <ServicesHero>
        <ServicesHeroContainer>
          <HeroTextGroup
            heading={SERVICES_HERO.heading}
            text={SERVICES_HERO.text}
          />

          <ServiceHeroImg
            src={SERVICES_HERO.image.src}
            alt={SERVICES_HERO.image.alt}
          />
        </ServicesHeroContainer>
      </ServicesHero>

      <ServicesSection>
        <ServiceHeading>
          <h2>
            <Accent>Our services</Accent>
          </h2>
          <p>Improve your business with the help our services</p>
        </ServiceHeading>
        <ServicesGrid>
          {SERVICE_CARDS.map((service, i) => (
            <ServicePageCard key={i} details={service} />
          ))}
        </ServicesGrid>
      </ServicesSection>

      {/* <FaqSection id="faq">
        <HeadingGroup
          heading="Frequently asked Questions"
          subheading="Here are answers to questions we feel you may have"
        />

        <Faq>
          {FAQS.map((faq, i) => (
            <Accordion key={i} title={faq.question}>
              {faq.answer}
            </Accordion>
          ))}
        </Faq>
      </FaqSection> */}
    </Layout>
  );
};

export default ServicesPage;
