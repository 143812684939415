import { Project } from "src/interfaces/constants";
import {
  ProjectDetails,
  ProjectImage,
  ProjectType,
  StyledProjectCard,
} from "./style";
import { RightArrow } from "../Icons";

interface Props {
  details: Project;
}

const ProjectCard: React.FC<Props> = ({
  details: { desc, image, link, name, type },
}) => {
  return (
    <StyledProjectCard>
      <ProjectImage src={image} alt={name} />

      <ProjectDetails>
        <ProjectType>{type}</ProjectType>

        <h3>{name}</h3>

        <p>{desc}</p>

        <a href={link}>
          Learn More
          <RightArrow />
        </a>
      </ProjectDetails>
    </StyledProjectCard>
  );
};

export default ProjectCard;
