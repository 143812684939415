import { motion } from "framer-motion";
import { Container } from "src/components/Container";
import styled from "styled-components";

export const AboutHeroContainer = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
  }
`;

export const HeroTextGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 40px;

  @media (max-width: 1023px) {
    text-align: center;
    align-items: center;
    gap: 22px;
  }
`;

export const AboutHeroImages = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;

  @media (max-width: 1023px) {
    justify-items: center;
    gap: 28px;
  }

  @media (max-width: 579px) {
    grid-template-columns: 1fr;
  }
`;

export const AboutImage = styled(motion.img)`
  width: 290px;
  height: 220px;
  object-fit: cover;
  border-radius: ${(props) => props.theme.radius.xl};

  @media (max-width: 1023px) {
    width: 100%;
    object-position: top;
  }
`;

export const MissionGridContainer = styled(Container)`
  display: grid;
  gap: 120px;
  padding-block: 120px;

  @media (max-width: 579px) {
    padding-block: 64px;
    gap: 64px;
  }
`;

export const MissionGrid = styled.section`
  display: grid;
  // grid-template-columns: 2fr 2fr;
  gap: 64px;

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const MissionText = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  h2 {
    font-size: ${(props) => props.theme.sizes.heading6};
    font-weight: 700;
    margin-block: 1.5rem;
  }

  p {
    font-size: ${(props) => props.theme.sizes.xl};
    line-height: 34px;
    color: ${(props) => props.theme.colors.gray[300]};

    @media (max-width: 579px) {
      font-size: 1rem;
    }
  }
`;

export const VisionStatment = styled.h1`
  font-weight: 800;
  color: ${(props) => props.theme.colors.gray[400]};
  font-size: ${(props) => props.theme.sizes.heading80};
`;

export const MissionImage = styled.img`
  width: 100%;
  height: 480px;
  object-fit: cover;
  border-top-right-radius: 64px;

  &[data-reverse="true"] {
    border-top-right-radius: 0;
    border-bottom-left-radius: 64px;
  }

  @media (max-width: 767px) {
    height: 350px;
  }

  @media (max-width: 579px) {
    height: 260px;
  }
`;

export const CoreValuesSection = styled.section`
  padding-block: 200px;
  background-color: ${(props) => props.theme.colors.lightBlue[200]};

  clip-path: polygon(0 150px, 100% 0%, 100% calc(100% - 150px), 0% 100%);

  @media (max-width: 767px) {
    clip-path: polygon(0 50px, 100% 0%, 100% calc(100% - 50px), 0% 100%);

    padding-block: 100px;
  }
`;

export const ValuesGrid = styled(Container)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  background-color: ${(props) => props.theme.colors.gray[100]};
  gap: 1px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 579px) {
    grid-template-columns: 1fr;
  }
`;

export const ValueTextGroup = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  gap: 1.5rem;
  padding: 4rem 3rem;
  background-color: ${(props) => props.theme.colors.lightBlue[200]};

  h3 {
    font-weight: 700;
    font-size: ${(props) => props.theme.sizes.lg};
  }

  p {
    color: ${(props) => props.theme.colors.gray[300]};
  }
`;

export const TeamSection = styled.section`
  padding-block: 100px;

  @media (max-width: 579px) {
    padding-block: 64px;
  }
`;

export const TeamGrid = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 579px) {
    grid-template-columns: 1fr;
    row-gap: 80px;
  }
`;
