import {
  InterestedServices,
  InterestedServicesWrapper,
} from './style';
import Button from '../Button';
import { StyledContactForm } from '../ContactForm/style';
import Input from '../Input';
import { FormGroup } from '../JobApplyForm/style';
import Textarea from '../TextArea';
import CheckCard from '../CheckCard';
import { SERVICE_CARDS } from 'src/constants/services';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useState } from 'react';
import {
  ServiceRequestFormProps,
  serviceRequestFormSchema,
} from './schema';
import { toast } from 'react-hot-toast';
import serviceRequestService from 'src/network/services/service-request';
import { useLocation } from 'react-router';

const ServiceRequestForm = () => {
  const [requestedServices, setRequestedServices] =
    useState<string[]>([]);
  const [isLoading, setLoading] = useState(false);

  //auto-set requested service if a user was
  //navigated here by clicking a service request card
  const location = useLocation();

  useEffect(() => {
    const referenceService = decodeURIComponent(
      location.search
    ).split('?')[1];

    if (!referenceService) return;

    setRequestedServices([referenceService]);
  }, [location.search]);

  const {
    register,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm<ServiceRequestFormProps>({
    resolver: zodResolver(serviceRequestFormSchema),
  });

  //for checkbox group
  const handleServiceSelection = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedService = e.target.value;

    if (requestedServices.includes(selectedService)) {
      setRequestedServices(
        requestedServices.filter(
          service => service !== selectedService
        )
      );
    } else {
      setRequestedServices([
        ...requestedServices,
        selectedService,
      ]);
    }
  };

  //handle form submission
  const handleServiceRequest = async (
    formData: ServiceRequestFormProps
  ) => {
    if (requestedServices.length === 0) {
      return toast.error(
        'Please select at least one(1) service'
      );
    }

    setLoading(true);

    try {
      const { status } = await serviceRequestService({
        ...formData,
        requestedServices,
      });

      if (status === 201) {
        toast.success('Request Sent');

        reset();
      } else {
        toast.error('Something went wrong');
      }
    } catch (error) {
      toast.error('Something went wrong');
      console.log(JSON.stringify(error, null, 2));
    } finally {
      setLoading(false);
    }
  };

  return (
    <StyledContactForm
      onSubmit={handleSubmit(handleServiceRequest)}
      onChange={() => clearErrors()}
    >
      <FormGroup>
        <Input
          bg="transparent"
          label="First Name"
          placeholder="Enter First Name"
          id="firstname"
          {...register('firstName')}
          error={!!errors.firstName}
          message={errors.firstName?.message}
        />
        <Input
          bg="transparent"
          label="Last Name"
          placeholder="Enter Last Name"
          id="lastname"
          {...register('lastName')}
          error={!!errors.lastName}
          message={errors.lastName?.message}
        />
      </FormGroup>

      <FormGroup>
        <Input
          bg="transparent"
          type="email"
          label="Email Address"
          placeholder="Enter Email Address"
          id="email"
          {...register('email')}
          error={!!errors.email}
          message={errors.email?.message}
        />
        <Input
          bg="transparent"
          label="Phone Number"
          placeholder="Enter Mobile Number"
          id="phone"
          {...register('phoneNumber')}
          error={!!errors.phoneNumber}
          message={errors.phoneNumber?.message}
        />
      </FormGroup>

      <InterestedServicesWrapper>
        <h3>What service are you interested in?</h3>

        <InterestedServices>
          {SERVICE_CARDS.map(service => (
            <CheckCard
              onChange={handleServiceSelection}
              id={service.title}
              value={service.title}
              key={service.title}
              label={service.title}
              checked={requestedServices.includes(
                service.title
              )}
            />
          ))}
        </InterestedServices>
      </InterestedServicesWrapper>

      <Textarea
        bg="transparent"
        label="Tell us more about the project"
        id="message"
        placeholder="Give us specific details"
        {...register('message')}
        error={!!errors.message}
        message={errors.message?.message}
      />

      <Button type="submit" loading={isLoading}>
        Submit
      </Button>
    </StyledContactForm>
  );
};

export default ServiceRequestForm;
