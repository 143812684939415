import { PortfolioCard, ServiceCard } from "src/interfaces/constants";
import servicesHeroImg from "../assets/servicesHeroImg.png";
import {
  BlockchainIcon,
  ChartIcon,
  DocumentCode,
  // MobileIcon,
  // StackIcon,
} from "src/components/Icons";

import service1 from "../assets/service1.png";
import service2 from "../assets/service2.png";
import mephalti_tech_portfolio from "../assets/mephalti-tech-portfolio-card.png";
import virgas_portfolio_card from "../assets/virgas-portfolio-card.png";
import virpem_portfolio_card from "../assets/virpem-portfolio-card.png";
// import service3 from "../assets/service3.png";
// import mobileDevImg from "../assets/mobile-dev.png";
import dataAnalysisImg from "../assets/data-analysis.png";
import Logo from "src/components/Logo";
import VirgasLogo from "src/components/Logo/virgasLogo";
import VirpemLogo from "src/components/Logo/virpemLogo";
// import dappsImg from "../assets/dapps.jpg";
// import fintechImg from "../assets/fintech.jpg";
// import healthTechImg from "../assets/health-tech.jpg";
// import businessAutomationImg from "../assets/business-automation.jpg";

export const SERVICES_HERO = {
  heading: "Expert Tech Services to Help Your Business Succeed",
  text: `Our team of experts has years of experience 
  working with a wide range of technologies and platforms, 
  including web applications, mobile apps and blockchain 
  technologies. `,
  image: {
    src: servicesHeroImg,
    alt: "Services",
  },
};

export const PORTFOLIO_CARDS: PortfolioCard[] = [
  {
    image: mephalti_tech_portfolio,
    title: "Mephalti Technology",
    link: "https://mephalti.com",
    icon: <Logo />,
  },
  {
    image: virgas_portfolio_card,
    title: "virgas",
    link: "https://virgasapp.com",
    icon: <VirgasLogo />,
  },
  {
    image: virpem_portfolio_card,
    title: "virpem",
    link: "https://virpem.com",
    icon: <VirpemLogo />,
  },
];

export const SERVICE_CARDS: ServiceCard[] = [
  {
    image: service1,
    title: "Software development",
    details: `Creating custom web/mobile solutions 
    to address your business needs by using the 
    latest tools and technologies.`,
    link: "/",
    icon: <DocumentCode />,
  },
  {
    image: service2,
    title: "Blockchain Services",
    details: `We stay up-to-date on blockchain 
    tech and trends to provide tailored high-quality 
    solutions.`,
    link: "/",
    icon: <BlockchainIcon />,
  },
  {
    image: dataAnalysisImg,
    title: "Data Analysis",
    details: `We analyze your data using advanced 
    techniques to optimize your operations with 
    actionable insights.`,
    link: "/",
    icon: <ChartIcon />,
  },
  // {
  //   image: dappsImg,
  //   title: 'Decentralized Apps (DApps)',
  //   details: `We create a variety of Decentralised
  //   Applications including those for decentralized
  //   finance, web browsing, social media, gaming and
  //   others tailored specifically to suit your needs.`,
  //   link: '/',
  //   icon: <StackIcon />,
  // },
  // {
  //   image: service3,
  //   title: 'Smart Contract development',
  //   details: `Creating smart contracts and
  //   decentralized applications (DApps) designed to
  //   optimise your operations.`,
  //   link: '/',
  //   icon: <BlockchainIcon />,
  // },
  // {
  //   image: mobileDevImg,
  //   title: 'Mobile app development',
  //   details: `Stunning and user-friendly mobile
  //   applications that run seamlessly on both iOS
  //   and Android platforms.`,
  //   link: '/',
  //   icon: <MobileIcon />,
  // },
  // {
  //   image: dataAnalysisImg,
  //   title: 'Data Analysis',
  //   details: `We analyze your data using advanced
  //   techniques to optimize your operations with
  //   actionable insights.`,
  //   link: '/',
  //   icon: <ChartIcon />,
  // },
  // {
  //   image: healthTechImg,
  //   title: 'Health Tech',
  //   details: `We specialize in improving the delivery
  //   of healthcare services, enhance patient outcomes and
  //   streamline healthcare processes with the application
  //   of technology.`,
  //   link: '/',
  //   icon: <DocumentCode />,
  // },
  // {
  //   image: fintechImg,
  //   title: 'Fintech',
  //   details: `We build fintech applications with our
  //   innovative use of technology to deliver financial
  //   products and services more efficiently, conveniently
  //   and securely with a wide range of applications including
  //   mobile payments, peer-to-peer lending and blockchain.`,
  //   link: '/',
  //   icon: <DocumentCode />,
  // },
  // {
  //   image: businessAutomationImg,
  //   title: 'Business Automation',
  //   details: `We specialize in the use of technology
  //   and software to streamline and automate various business
  //   processes that help businesses save cost and improve operations.`,
  //   link: '/',
  //   icon: <DocumentCode />,
  // },
];
