import Layout from 'src/components/Layout';
import {
  HeroImage,
  Projects,
  ProjectsHero,
  ProjectsHeroContainer,
  TextGroup,
} from './style';
import { HeroHeading, HeroText } from '../Landing/style';
import {
  PROJECTS,
  PROJECTS_HERO,
} from 'src/constants/projects';
import ProjectCard from 'src/components/ProjectCard';

const ProjectsPage = () => {
  return (
    <Layout>
      <ProjectsHero>
        <ProjectsHeroContainer>
          <TextGroup>
            <HeroHeading>
              {PROJECTS_HERO.heading}
            </HeroHeading>
            <HeroText>{PROJECTS_HERO.text}</HeroText>
          </TextGroup>

          <HeroImage
            src={PROJECTS_HERO.image.src}
            alt={PROJECTS_HERO.image.alt}
          />
        </ProjectsHeroContainer>
      </ProjectsHero>

      <Projects>
        {PROJECTS.map((project, i) => (
          <ProjectCard key={i} details={project} />
        ))}
      </Projects>
    </Layout>
  );
};

export default ProjectsPage;
