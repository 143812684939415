import { forwardRef } from 'react';
import {
  InputMessage,
  InputWrapper,
  StyledInput,
  StyledLabel,
} from '../Input/style';

interface TextareaProps
  extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: boolean;
  message?: string;
  bg?: 'gray' | 'transparent';
}

const Textarea = forwardRef<
  HTMLTextAreaElement,
  TextareaProps
>(
  (
    {
      label,
      error = false,
      message,
      id,
      bg = 'default',
      ...props
    },
    ref
  ) => {
    return (
      <InputWrapper>
        {label ? (
          <StyledLabel htmlFor={id}>{label}</StyledLabel>
        ) : (
          false
        )}

        <StyledInput
          style={{ minHeight: '148px' }}
          as="textarea"
          data-bg={bg}
          id={id}
          ref={ref}
          {...props}
          data-error={error}
        />

        {message ? (
          <InputMessage>{message}</InputMessage>
        ) : (
          false
        )}
      </InputWrapper>
    );
  }
);

Textarea.displayName = 'Textarea';
export default Textarea;
